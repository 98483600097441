import api from "../../service/api"

export const FETCH_TASKS_SUCCESS_ADMIN = "FETCH_TASKS_SUCCESS_ADMIN"
export const FETCH_TASKS_ERROR_ADMIN = "FETCH_TASKS_ERROR_ADMIN"

export const FETCH_TASK_SUCCESS_ADMIN = "FETCH_TASK_SUCCESS_ADMIN"
export const FETCH_TASK_ERROR_ADMIN = "FETCH_TASK_ERROR_ADMIN"

export const FETCH_REFERENCE_SUCCESS_ADMIN = "FETCH_REFERENCE_SUCCESS_ADMIN"
export const FETCH_REFERENCE_ERROR_ADMIN = "FETCH_REFERENCE_ERROR_ADMIN"

export const ACCEPT_TASK_SUCCESS_ADMIN = "ACCEPT_TASK_SUCCESS_ADMIN"
export const ACCEPT_TASK_ERROR_ADMIN = "ACCEPT_TASK_ERROR_ADMIN"

export const GET_URL_UPLOAD_TASK_BY_ID_SUCCESS_ADMIN =
	"GET_URL_UPLOAD_TASK_BY_ID_SUCCESS_ADMIN"
export const GET_URL_UPLOAD_TASK_NY_ID_ERROR_ADMIN =
	"GET_URL_UPLOAD_TASK_NY_ID_ERROR_ADMIN"

export const GET_URL_FROM_KEY_SUCCESS_ADMIN = "GET_URL_FROM_KEY_SUCCESS_ADMIN"
export const GET_URL_FROM_KEY_ERROR_ADMIN = "GET_URL_FROM_KEY_ERROR_ADMIN"

export const DELETE_FILE_TASK_SUCCESS_ADMIN = "DELETE_FILE_TASK_SUCCESS_ADMIN"
export const DELETE_FILE_TASK_ERROR_ADMIN = "DELETE_FILE_TASK_ERROR_ADMIN"

export const CLEAR_VALUE_IN_STORE_TASK_ADMIN = "CLEAR_VALUE_IN_STORE_TASK_ADMIN"

export const SET_VALUE_IN_STORE_TASK_ADMIN = "SET_VALUE_IN_STORE_TASK_ADMIN"

export const GET_OTHER_TASK_ID_SUCCESS_ADMIN = "GET_OTHER_TASK_ID_SUCCESS_ADMIN"
export const GET_OTHER_TASK_ID_ERROR_ADMIN = "GET_OTHER_TASK_ID_ERROR_ADMIN"

export const SUBMIT_TASK_SUCCESS_ADMIN = "SUBMIT_TASK_SUCCESS_ADMIN"
export const SUBMIT_TASK_ERROR_ADMIN = "SUBMIT_TASK_ERROR_ADMIN"

export const SEND_VALUE_PAY_SUCCESS_ADMIN = "SEND_VALUE_PAY_SUCCESS_ADMIN"
export const SEND_VALUE_PAY_ERROR_ADMIN = "SEND_VALUE_PAY_ERROR_ADMIN"

// export const UPDATE_VALUE_PAY_SUCCESS_ADMIN = "UPDATE_VALUE_PAY_SUCCESS_ADMIN"
// export const UPDATE_VALUE_PAY_ERROR_ADMIN = "UPDATE_VALUE_PAY_ERROR_ADMIN"

export const GET_URL_DOWNLOAD_PAY_SUCCESS_ADMIN =
	"GET_URL_DOWNLOAD_PAY_SUCCESS_ADMIN"
export const GET_URL_DOWNLOAD_PAY_ERROR_ADMIN =
	"GET_URL_DOWNLOAD_PAY_ERROR_ADMIN"

export const GET_URL_UPLOAD_FILE_PAY_SUCCESS_ADMIN =
	"GET_URL_UPLOAD_FILE_PAY_SUCCESS_ADMIN"
export const GET_URL_UPLOAD_FILE_PAY_ERROR_ADMIN =
	"GET_URL_UPLOAD_FILE_PAY_ERROR_ADMIN"

export const HANDLE_INPUT_SEARCH_TASK_ADMIN = "HANDLE_INPUT_SEARCH_TASK_ADMIN"

export const SEARCH_TASK_SUCCESS_ADMIN = "SEARCH_TASK_SUCCESS_ADMIN"
export const SEARCH_TASK_ERROR_ADMIN = "SEARCH_TASK_ERROR_ADMIN"

export const initialstate = {
	tasks: [],
	reference: [],
	task: {
		createdAt: null,
		deletedAt: null,
		id: null,
		order: {
			id: null,
			od_status: null,
			province: {
				id: null,
				pv_name: ""
			},
			user_customer: {
				createdAt: null,
				id: null,
				uc_email: "",
				uc_firstname: "",
				uc_lastname: "",
				uc_tel: ""
			}
		},
		orderId: "",
		service: {
			sv_name: ""
		},
		serviceId: null,
		task_files: [],
		tk_code: "",
		tk_date_accept: null,
		tk_date_submit: null,
		tk_status: null,
		tk_working: null,
		updatedAt: null,
		userAdminId: null,
		userLawyerId: null,
		uuid: null
	},
	search: {
		tk_code: "",
		od_code: "",
		pv_name: "",
		tk_status_name: ""
	},
	isLoading: true,
	isTaskFetched: false,
	isAccepted: false,
	isWaiting: false,
	fileafterupload: [],
	urlForDownload: null,
	isSubmit: false,
	report_id: null,
	contract_id: null,
	transfer_id: null,
	review_report_id: null,
	review_contract_id: null,
	isPay: false,
	isPayUpdate: false,
	uploadPayKeyUrl: null,
	urlDownloadPay: null,
	payId: null,
	start: 0,
	have: 0,
	count: 0,
	isSearching: false
}

export default (state = initialstate, action) => {
	switch (action.type) {
		case FETCH_TASKS_SUCCESS_ADMIN:
			return {
				...state,
				have: action.payload.data.length,
				count: action.payload.count,
				tasks: [...state.tasks, ...action.payload.data],
				isLoading: false
			}
		//ไม่ได้ใช้ have,countแล้ว
		case FETCH_TASKS_ERROR_ADMIN:
			return {
				...state,
				isLoading: false
			}
		case FETCH_TASK_SUCCESS_ADMIN:
			return {
				...state,
				task: {
					...action.payload.data
				},
				isTaskFetched: true
			}
		case FETCH_TASK_ERROR_ADMIN:
			return {
				...state,
				isTaskFetched: true
			}
		case FETCH_REFERENCE_SUCCESS_ADMIN:
			return {
				...state,
				reference:
					action.payload.data.length > 0
						? [...action.payload.data]
						: []
			}
		case FETCH_REFERENCE_ERROR_ADMIN:
			return {
				...state,
				reference: []
			}
		case ACCEPT_TASK_SUCCESS_ADMIN:
			return {
				...state,
				isAccepted: true,
				isWaiting: false
			}
		case ACCEPT_TASK_ERROR_ADMIN:
			return {
				...state,
				isAccepted: false,
				isWaiting: false
			}
		case SET_VALUE_IN_STORE_TASK_ADMIN:
			return {
				...state,
				[action.payload.name]: action.payload.value
			}
		case CLEAR_VALUE_IN_STORE_TASK_ADMIN:
			return {
				...initialstate
			}
		case GET_URL_UPLOAD_TASK_BY_ID_SUCCESS_ADMIN:
			return {
				...state,
				fileafterupload: [...action.payload.data]
			}
		case GET_URL_UPLOAD_TASK_NY_ID_ERROR_ADMIN:
			return {
				...state,
				fileafterupload: []
			}
		case GET_URL_FROM_KEY_SUCCESS_ADMIN:
			return {
				...state,
				urlForDownload: action.payload.data
			}
		case GET_URL_FROM_KEY_ERROR_ADMIN:
			return {
				...state,
				urlForDownload: null
			}
		case SUBMIT_TASK_SUCCESS_ADMIN:
			return {
				...state,
				isSubmit: true
			}
		case SUBMIT_TASK_ERROR_ADMIN:
			return {
				...state,
				isSubmit: false
			}
		case GET_OTHER_TASK_ID_SUCCESS_ADMIN:
			let report_temp = null
			let contract_temp = null
			let transfer_temp = null
			let review_report_temp = null
			let review_contract_temp = null
			if (
				action.payload.data &&
				action.payload.data.tasks &&
				action.payload.data.tasks.length > 0
			) {
				action.payload.data.tasks.forEach(task => {
					if (task.service) {
						if (task.service.sv_name === "Report") {
							report_temp = task.id
						} else if (task.service.sv_name === "Review report") {
							review_report_temp = task.id
						} else if (task.service.sv_name === "Contract") {
							contract_temp = task.id
						} else if (task.service.sv_name === "Review contract") {
							review_contract_temp = task.id
						} else if (task.service.sv_name === "Transfer") {
							transfer_temp = task.id
						}
					}
				})
			}
			return {
				...state,
				report_id: report_temp,
				contract_id: contract_temp,
				transfer_id: transfer_temp,
				review_report_id: review_report_temp,
				review_contract_id: review_contract_temp
			}
		case GET_OTHER_TASK_ID_ERROR_ADMIN:
			return {
				...state,
				report_id: null,
				contract_id: null,
				transfer_id: null,
				review_report_id: null,
				review_contract_id: null
			}
		case SEND_VALUE_PAY_SUCCESS_ADMIN:
			return {
				...state,
				isPay: true,
				payId: action.payload.data.id
			}
		case SEND_VALUE_PAY_ERROR_ADMIN:
			return {
				...state,
				isPay: false,
				payId: null
			}
		// case UPDATE_VALUE_PAY_SUCCESS_ADMIN:
		// 	return {
		// 		...state,
		// 		isPayUpdate: true
		// 	}
		// case UPDATE_VALUE_PAY_ERROR_ADMIN:
		// 	return {
		// 		...state,
		// 		isPayUpdate: false
		// 	}
		case GET_URL_UPLOAD_FILE_PAY_SUCCESS_ADMIN:
			return {
				...state,
				uploadPayKeyUrl: action.payload
			}
		case GET_URL_UPLOAD_FILE_PAY_ERROR_ADMIN:
			return {
				...state,
				uploadPayKeyUrl: null
			}
		case GET_URL_DOWNLOAD_PAY_SUCCESS_ADMIN:
			return {
				...state,
				urlDownloadPay: action.payload.data
			}
		case GET_URL_DOWNLOAD_PAY_ERROR_ADMIN:
			return {
				...state,
				urlDownloadPay: null
			}
		case HANDLE_INPUT_SEARCH_TASK_ADMIN:
			return {
				...state,
				search: {
					...state.search,
					[action.payload.name]: action.payload.value
				}
			}
		case SEARCH_TASK_SUCCESS_ADMIN:
			return {
				...state,
				tasks: [...state.tasks, ...action.payload.data]
			}
		case SEARCH_TASK_ERROR_ADMIN:
			return {
				...state,
				tasks: [...state.tasks]
			}
		default:
			return state
	}
}

export const fetchTasks = (type, start) => {
	return api(
		`/api/task?start=${start}&service=${type}`,
		{
			success: FETCH_TASKS_SUCCESS_ADMIN,
			error: FETCH_TASKS_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const fetchTask = id => {
	return api(
		`/api/task/${id}`,
		{
			success: FETCH_TASK_SUCCESS_ADMIN,
			error: FETCH_TASK_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const fetchReference = id => {
	return api(
		`/api/task/reference/${id}`,
		{
			success: FETCH_REFERENCE_SUCCESS_ADMIN,
			error: FETCH_REFERENCE_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const acceptTask = id => {
	return api(
		`/api/task/accept/${id}`,
		{
			success: ACCEPT_TASK_SUCCESS_ADMIN,
			error: ACCEPT_TASK_ERROR_ADMIN
		},
		"PUT",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const getUrlUploadFileById = (id, data) => {
	return api(
		`/api/upload/task/${id}`,
		{
			success: GET_URL_UPLOAD_TASK_BY_ID_SUCCESS_ADMIN,
			error: GET_URL_UPLOAD_TASK_NY_ID_ERROR_ADMIN
		},
		"POST",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const getUrlFromKey = data => {
	let filename = data.replace("task/", "")
	return api(
		`/api/upload/task/${filename}`,
		{
			success: GET_URL_FROM_KEY_SUCCESS_ADMIN,
			error: GET_URL_FROM_KEY_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const pushkeyfile = (id, file_url) => dispatch => {
	return api(
		"/api/file/" + id,
		{
			success: "PUSH_KEY_FILE_ADMIN_SUCCESS_ADMIN",
			error: "PUSH_KEY_FILE_ADMIN_ERROR_ADMIN"
		},
		"POST",
		file_url,
		"FETCH_DATA",
		"admin"
	)(dispatch)
}

export const deletefiletask = id => {
	return api(
		"/api/file/" + id,
		{
			success: DELETE_FILE_TASK_SUCCESS_ADMIN,
			error: DELETE_FILE_TASK_ERROR_ADMIN
		},
		"DELETE",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const submittask = id => dispatch => {
	return api(
		"/api/task/submit/" + id,
		{
			success: SUBMIT_TASK_SUCCESS_ADMIN,
			error: SUBMIT_TASK_ERROR_ADMIN
		},
		"PUT",
		{},
		"FETCH_DATA",
		"admin"
	)(dispatch)
}

export const setValue = data => {
	return {
		type: SET_VALUE_IN_STORE_TASK_ADMIN,
		payload: {
			name: data.target.name,
			value: data.target.value
		}
	}
}

export const clearValueInStoreTask = () => {
	return {
		type: CLEAR_VALUE_IN_STORE_TASK_ADMIN
	}
}

export const fetchOtherTaskId = id => {
	return api(
		`/api/order/${id}`,
		{
			success: GET_OTHER_TASK_ID_SUCCESS_ADMIN,
			error: GET_OTHER_TASK_ID_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const sendValuePay = data => {
	return api(
		`/api/pay`,
		{
			success: SEND_VALUE_PAY_SUCCESS_ADMIN,
			error: SEND_VALUE_PAY_ERROR_ADMIN
		},
		"POST",
		data,
		"FETCH_DATA",
		"admin"
	)
}

// export const updateValuePay = (id, data) => {
// 	return api(
// 		`/api/pay/${id}`,
// 		{
// 			success: UPDATE_VALUE_PAY_SUCCESS_ADMIN,
// 			error: UPDATE_VALUE_PAY_ERROR_ADMIN
// 		},
// 		"PUT",
// 		data,
// 		"FETCH_DATA",
// 		"admin"
// 	)
// }

export const getUrlUploadFilePay = (id, data) => {
	return api(
		`/api/upload/pay/${id}`,
		{
			success: GET_URL_UPLOAD_FILE_PAY_SUCCESS_ADMIN,
			error: GET_URL_UPLOAD_FILE_PAY_ERROR_ADMIN
		},
		"POST",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const getUrlDownloadPay = data => {
	let filename = data.replace("pay/", "")
	return api(
		`/api/upload/pay/${filename}`,
		{
			success: GET_URL_DOWNLOAD_PAY_SUCCESS_ADMIN,
			error: GET_URL_DOWNLOAD_PAY_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const handleInputSearchTask = ({ target }) => {
	return {
		type: HANDLE_INPUT_SEARCH_TASK_ADMIN,
		payload: {
			name: target.name,
			value: target.value
		}
	}
}

export const searchTask = (data, start) => {
	return api(
		`/api/task/search?start=${start}`,
		{
			success: SEARCH_TASK_SUCCESS_ADMIN,
			error: SEARCH_TASK_ERROR_ADMIN
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}
