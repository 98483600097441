import React, { Component } from "react"
import { connect } from "react-redux"
import PrivateRouterLawyer from "./PrivateRouterLawyer"
// import { bindActionCreators } from "redux"
import { Switch, Route } from "react-router-dom"
import {
	LoginLawyer,
	RegisterLawyer,
	ForgetPasswordLawyer,
	ResetPasswordLawyer
} from "../../views"

class index extends Component {
	constructor(props) {
		super(props)
		if (this.props.isLawyer) {
			import("../../css/lawyer/styles.css")
			import("../../css/lawyer/auth.css")
		}
	}
	render() {
		return (
			<Switch>
				{/* <Route exact path='/lawyer' component={LoginLawyer} /> */}
				<Route exact path='/lawyer/login' component={LoginLawyer} />
				<Route
					exact
					path='/lawyer/resetpassword'
					component={ResetPasswordLawyer}
				/>
				<Route
					exact
					path='/lawyer/forgetpassword'
					component={ForgetPasswordLawyer}
				/>
				<Route
					exact
					path='/lawyer/register'
					component={RegisterLawyer}
				/>
				<PrivateRouterLawyer isAuth={this.props.isAuthLawyer} />
				<Route render={() => <div>404</div>} />
			</Switch>
		)
	}
}
const mapStateToProps = ({ AuthLawyer }) => ({ ...AuthLawyer })

export default connect(mapStateToProps)(index)
