import React, { Component } from "react"
import { connect } from "react-redux"
// import { bindActionCreators } from "redux"
import { Switch, Route } from "react-router-dom"

import PrivateRouterAdmin from "./PrivateRouterAdmin"
import {
	LoginAdmin,
	ForgetPasswordAdmin,
	ResetPasswordAdmin
} from "../../views"

class index extends Component {
	constructor(props) {
		super(props)

		if (this.props.isAdmin) {
			import("../../css/admin/core.css")
			import("../../css/admin/menu.css")
			import("../../css/admin/auth.css")
			import("../../css/admin/custom.css")
			import("react-draft-wysiwyg/dist/react-draft-wysiwyg.css")
		}
	}

	render() {
		return (
			<Switch>
				<Route exact path='/admin/login' component={LoginAdmin} />
				<Route
					exact
					path='/admin/forgetpassword'
					component={ForgetPasswordAdmin}
				/>
				<Route
					exact
					path='/admin/resetpassword'
					component={ResetPasswordAdmin}
				/>
				<PrivateRouterAdmin />
				<Route render={() => <div>404</div>} />
			</Switch>
		)
	}
}
const mapStateToProps = ({ AuthAdmin }) => ({ ...AuthAdmin })

export default connect(mapStateToProps)(index)
