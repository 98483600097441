import token from "../../service/token"
import api from "../../service/api"

export const CHANGE_STORE_AUTH_ADMIN = "CHANGE_STORE_AUTH_ADMIN"

export const SEND_NEW_PASSWORD_SUCCESS_ADMIN = "SEND_NEW_PASSWORD_SUCCESS_ADMIN"
export const SEND_NEW_PASSWORD_ERROR_ADMIN = "SEND_NEW_PASSWORD_ERROR_ADMIN"

export const FETCH_ADMIN_PROFILE_SUCCESS_ADMIN =
	"FETCH_ADMIN_PROFILE_SUCCESS_ADMIN"
export const FETCH_ADMIN_PROFILE_ERROR_ADMIN = "FETCH_ADMIN_PROFILE_ERROR_ADMIN"

export const UPDATE_ADMIN_PROFILE_SUCCESS_ADMIN =
	"UPDATE_ADMIN_PROFILE_SUCCESS_ADMIN"
export const UPDATE_ADMIN_PROFILE_ERROR_ADMIN =
	"UPDATE_ADMIN_PROFILE_ERROR_ADMIN"

export const SET_FALSE_ADMIN = "SET_FALSE_ADMIN"

export const SET_VALUE_IN_STORE_AUTH_ADMIN = "SET_VALUE_IN_STORE_AUTH_ADMIN"

export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS"
export const LOGIN_ADMIN_ERROR = "LOGIN_ADMIN_ERROR"

export const LOGOUT_ADMIN_SUCCESS = "LOGOUT_ADMIN_SUCCESS"
export const LOGOUT_ADMIN_ERROR = "LOGOUT_ADMIN_ERROR"

export const SEND_EMAIL_FORGET_PASS_ADMIN_SUCCESS =
	"SEND_EMAIL_FORGET_PASS_ADMIN_SUCCESS"
export const SEND_EMAIL_FORGET_PASS_ADMIN_ERROR =
	"SEND_EMAIL_FORGET_PASS_ADMIN_ERROR"

export const RESET_PASSWORD_ADMIN_SUCCESS = "RESET_PASSWORD_ADMIN_SUCCESS"
export const RESET_PASSWORD_ADMIN_ERROR = "RESET_PASSWORD_ADMIN_ERROR"

export const initialstate = {
	user: {
		ad_email: "",
		ad_password: "",
		ad_firstname: "",
		ad_lastname: "",
		ad_tel: "",
		id: ""
	},
	isAuth: !!token.getToken("admin"),
	// isAuth: true,
	isPasswordReset: false,
	isProfileUpdated: false,
	errorMessage: "",
	isForgetPasswordSuccess: false,
	changeIfFetched: false
}

export default function(state = initialstate, action) {
	switch (action.type) {
		case LOGIN_ADMIN_SUCCESS: {
			token.setToken(action.payload.data, "admin")

			return {
				...state,
				isAuth: true
			}
		}
		case LOGIN_ADMIN_ERROR: {
			return {
				...state,
				isAuth: false
			}
		}
		case CHANGE_STORE_AUTH_ADMIN:
			return {
				...state,
				user: {
					...state.user,
					[action.payload.name]: action.payload.value
				}
			}
		case LOGOUT_ADMIN_SUCCESS:
			token.removeToken("admin")
			return {
				...initialstate,
				isAuth: false,
				isPasswordReset: state.isPasswordReset
			}
		case LOGOUT_ADMIN_ERROR:
			token.removeToken("admin")
			return {
				...initialstate,
				isAuth: false,
				isPasswordReset: state.isPasswordReset
			}
		case SEND_NEW_PASSWORD_SUCCESS_ADMIN:
			return {
				...state,
				isPasswordReset: true
			}
		case SEND_NEW_PASSWORD_ERROR_ADMIN:
			return {
				...state,
				isPasswordReset: false,
				errorMessage: action.payload.data.msg || ""
			}
		case FETCH_ADMIN_PROFILE_SUCCESS_ADMIN:
			return {
				...state,
				user: {
					...state.user,
					...action.payload.data
				},
				changeIfFetched: !state.changeIfFetched
			}
		case SET_FALSE_ADMIN:
			return {
				...state,
				[action.payload]: false
			}
		case UPDATE_ADMIN_PROFILE_SUCCESS_ADMIN:
			return {
				...state,
				user: {
					...state.user,
					...action.payload.data
				},
				isProfileUpdated: true
			}
		case UPDATE_ADMIN_PROFILE_ERROR_ADMIN:
			return {
				...state,
				isProfileUpdated: false
			}
		case SET_VALUE_IN_STORE_AUTH_ADMIN:
			return {
				...state,
				[action.payload.name]: action.payload.value
			}
		case SEND_EMAIL_FORGET_PASS_ADMIN_SUCCESS:
			return {
				...state,
				isForgetPasswordSuccess: true
			}
		case SEND_EMAIL_FORGET_PASS_ADMIN_ERROR:
			return {
				...state,
				isForgetPasswordSuccess: false
			}
		case RESET_PASSWORD_ADMIN_SUCCESS:
			return {
				...state,
				isPasswordReset: true
			}
		case RESET_PASSWORD_ADMIN_ERROR:
			return {
				...state,
				isPasswordReset: false
			}
		default:
			return state
	}
}

export const handleInputAdmin = ({ target }) => {
	return {
		type: CHANGE_STORE_AUTH_ADMIN,
		payload: {
			name: target.name,
			value: target.value
		}
	}
}

export const sendNewPasswordProfileAdmin = data => {
	return api(
		"/auth/changepassword/admin",
		{
			success: SEND_NEW_PASSWORD_SUCCESS_ADMIN,
			error: SEND_NEW_PASSWORD_ERROR_ADMIN
		},
		"PUT",
		data
	)
}

export const fetchAdminProfile = () => {
	return api("/api/admin/profile", {
		success: FETCH_ADMIN_PROFILE_SUCCESS_ADMIN,
		error: FETCH_ADMIN_PROFILE_ERROR_ADMIN
	})
}

export const updateAdminProfile = (id, data) => {
	return api(
		`/api/admin/${id}`,
		{
			success: UPDATE_ADMIN_PROFILE_SUCCESS_ADMIN,
			error: UPDATE_ADMIN_PROFILE_ERROR_ADMIN
		},
		"PUT",
		data
	)
}

export const setFalseAdmin = data => {
	return {
		type: SET_FALSE_ADMIN,
		payload: data
	}
}

export const login = data => {
	return api(
		"/auth/signin/admin",
		{
			success: LOGIN_ADMIN_SUCCESS,
			error: LOGIN_ADMIN_ERROR
		},
		"POST",
		data
	)
}

export const logout = () => {
	return {
		type: LOGOUT_ADMIN_SUCCESS
	}
}

export const setValue = data => {
	return {
		type: SET_VALUE_IN_STORE_AUTH_ADMIN,
		payload: data
	}
}

export const sendEmailForgetPasswordAdmin = data => {
	return api(
		"/auth/forgetpassword/admin",
		{
			success: SEND_EMAIL_FORGET_PASS_ADMIN_SUCCESS,
			error: SEND_EMAIL_FORGET_PASS_ADMIN_ERROR
		},
		"POST",
		data
	)
}

export const resetPassword = data => {
	return api(
		"/auth/resetpassword",
		{
			success: RESET_PASSWORD_ADMIN_SUCCESS,
			error: RESET_PASSWORD_ADMIN_ERROR
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}
