import api from "../../service/api"

export const FETCH_PACKAGES_SUCCESS_ADMIN = "FETCH_PACKAGES_SUCCESS_ADMIN"
export const FETCH_PACKAGES_ERROR_ADMIN = "FETCH_PACKAGES_ERROR_ADMIN"

export const FETCH_PACKAGE_SUCCESS_ADMIN = "FETCH_PACKAGE_SUCCESS_ADMIN"
export const FETCH_PACKAGE_ERROR_ADMIN = "FETCH_PACKAGE_ERROR_ADMIN"

export const UPDATE_PACKAGE_SUCCESS_ADMIN = "UPDATE_PACKAGE_SUCCESS_ADMIN"
export const UPDATE_PACKAGE_ERROR_ADMIN = "UPDATE_PACKAGE_ERROR_ADMIN"

export const CLEAR_ALL_VALUE_IN_STORE_PACKAGE =
      "CLEAR_ALL_VALUE_IN_STORE_PACKAGE"
export const CLEAR_PACKAGE_IN_STORE = "CLEAR_PACKAGE_IN_STORE"
export const CLEAR_PACKAGES_IN_STORE = "CLEAR_PACKAGES_IN_STORE"

export const CHANGE_VALUE_IN_STORE_PACKAGE = "CHANGE_VALUE_IN_STORE_PACKAGE"

export const CHANGE_STATUS_PACKAGE_SUCCESS_PACKAGE =
      "CHANGE_STATUS_PACKAGE_SUCCESS_PACKAGE"
export const CHANGE_STATUS_PACKAGE_ERROR_PACKAGE =
      "CHANGE_STATUS_PACKAGE_ERROR_PACKAGE"

export const GET_URL_UPLOAD_IMAGE_PACKAGE_SUCCESS_ADMIN =
      "GET_URL_UPLOAD_IMAGE_PACKAGE_SUCCESS_ADMIN"
export const GET_URL_UPLOAD_IMAGE_PACKAGE_ERROR_ADMIN =
      "GET_URL_UPLOAD_IMAGE_PACKAGE_ERROR_ADMIN"

export const SEND_KEY_GET_URL_PACKAGE_SUCCESS_ADMIN =
      "SEND_KEY_GET_URL_PACKAGE_SUCCESS_ADMIN"
export const SEND_KEY_GET_URL_PACKAGE_ERROR_ADMIN =
      "SEND_KEY_GET_URL_PACKAGE_ERROR_ADMIN"

export const initialstate = {
      packages: [],
      package: {
            id: "",
            pk_name: "",
            pk_short_description: "",
            pk_description: "",
            pk_code: "",
            pk_price_start: "",
            pk_price_end: "",
            pk_status: "",
            pk_status_mirror: "",
            createdAt: "",
            updatedAt: "",
            deletedAt: "",
            descriptionImage: ""
      },
      isPackageUpdate: false,
      isStatusChanged: false,
      isLoading: true
}

export default (state = initialstate, action) => {
      switch (action.type) {
            case FETCH_PACKAGES_SUCCESS_ADMIN: {
                  return {
                        ...state,
                        packages: action.payload.data,
                        isLoading: false
                  }
            }
            case FETCH_PACKAGES_ERROR_ADMIN: {
                  return {
                        ...state,
                        isLoading: false
                  }
            }
            case FETCH_PACKAGE_SUCCESS_ADMIN: {
                  return {
                        ...state,
                        package: {
                              ...state.package,
                              ...action.payload.data,
                              pk_status_mirror: action.payload.data["pk_status"]
                                    ? "Active"
                                    : "Inactive"
                        }
                  }
            }
            case CLEAR_PACKAGES_IN_STORE: {
                  return {
                        ...state,
                        packages: initialstate.packages
                  }
            }
            case CLEAR_PACKAGE_IN_STORE: {
                  return {
                        ...state,
                        package: initialstate.package
                  }
            }
            case CHANGE_VALUE_IN_STORE_PACKAGE: {
                  return {
                        ...state,
                        package: {
                              ...state.package,
                              [action.payload.name]: action.payload.value
                        }
                  }
            }

            case UPDATE_PACKAGE_SUCCESS_ADMIN: {
                  return {
                        ...state,
                        isPackageUpdate: true
                  }
            }
            case UPDATE_PACKAGE_ERROR_ADMIN: {
                  return {
                        ...state,
                        isPackageUpdate: false
                  }
            }
            case CHANGE_STATUS_PACKAGE_SUCCESS_PACKAGE: {
                  return {
                        ...state,
                        isStatusChanged: true
                  }
            }
            case CHANGE_STATUS_PACKAGE_ERROR_PACKAGE: {
                  return {
                        ...state,
                        isStatusChanged: false
                  }
            }
            case CLEAR_ALL_VALUE_IN_STORE_PACKAGE: {
                  return {
                        ...initialstate
                  }
            }
            case GET_URL_UPLOAD_IMAGE_PACKAGE_SUCCESS_ADMIN: {
                  return {
                        ...state,
                        package: {
                              ...state.package,
                              descriptionImage: action.payload
                        }
                  }
            }
            case GET_URL_UPLOAD_IMAGE_PACKAGE_ERROR_ADMIN: {
                  return {
                        ...state,
                        package: {
                              ...state.package,
                              descriptionImage: ""
                        }
                  }
            }
            case SEND_KEY_GET_URL_PACKAGE_SUCCESS_ADMIN: {
                  return {
                        ...state,
                        package: {
                              ...state.package,
                              descriptionImage: action.payload
                        }
                  }
            }
            case SEND_KEY_GET_URL_PACKAGE_ERROR_ADMIN: {
                  return {
                        ...state,
                        package: {
                              ...state.package,
                              descriptionImage: ""
                        }
                  }
            }
            case "DUMMY_PACKAGES": {
                  return {
                        ...state,
                        packages: action.payload
                  }
            }
            default:
                  return state
      }
}

export const fetchPackages = () => {
      return api(
            "/api/package",
            {
                  success: FETCH_PACKAGES_SUCCESS_ADMIN,
                  error: FETCH_PACKAGES_ERROR_ADMIN
            },
            "GET",
            {},
            "FETCH_DATA",
            "admin"
      )
}

export const fetchPackage = id => {
      return api(
            `/public/package/${id}`,
            {
                  success: FETCH_PACKAGE_SUCCESS_ADMIN,
                  error: FETCH_PACKAGE_ERROR_ADMIN
            },
            "GET",
            id
      )
}

export const updatePackage = (id, data) => {
      return api(
            `/api/package/${id}`,
            {
                  success: UPDATE_PACKAGE_SUCCESS_ADMIN,
                  error: UPDATE_PACKAGE_ERROR_ADMIN
            },
            "PUT",
            data,
            "FETCH_DATA",
            "admin"
      )
}

export const clearPackageInStore = () => {
      return {
            type: CLEAR_PACKAGE_IN_STORE
      }
}

export const clearPackagesInStore = () => {
      return {
            type: CLEAR_PACKAGES_IN_STORE
      }
}

export const dummy = () => {
      return {
            type: "DUMMY_PACKAGES",
            payload: [
                  {
                        id: 1,
                        package_name: "Pack A",
                        package_price_start: 500000.75,
                        package_price_end: 1000000,
                        package_price: 10000,
                        package_code: "PKG001"
                  },
                  {
                        id: 2,
                        package_name: "Pack B",
                        package_price_start: 500,
                        package_price_end: 10000,
                        package_price: 100,
                        package_code: "PKG002"
                  }
            ]
      }
}

export const changeValueInStore = ({ target }) => {
      return {
            type: CHANGE_VALUE_IN_STORE_PACKAGE,
            payload: {
                  name: target.name,
                  value: target.value
            }
      }
}

// export const changepackageStatus = () => {
// 	return {
// 		type: "NOT DONE"
// 	}
// }

export const changeStatusPackage = (id, data) => {
      return api(
            `/api/package/status/${id}`,
            {
                  success: CHANGE_STATUS_PACKAGE_SUCCESS_PACKAGE,
                  error: CHANGE_STATUS_PACKAGE_ERROR_PACKAGE
            },
            "PUT",
            data,
            "FETCH_DATA",
            "admin"
      )
}

export const clearToInitialStatePackage = () => {
      return {
            type: CLEAR_ALL_VALUE_IN_STORE_PACKAGE
      }
}

export const getUrlUploadImage = (id, data) => {
      return api(
            `/api/upload/package/${id}`,
            {
                  success: GET_URL_UPLOAD_IMAGE_PACKAGE_SUCCESS_ADMIN,
                  error: GET_URL_UPLOAD_IMAGE_PACKAGE_ERROR_ADMIN
            },
            "POST",
            data,
            "FETCH_DATA",
            "admin"
      )
}

export const sendKeyGetUrl = data => {
      return api(
            `/api/upload/${data}`,
            {
                  success: SEND_KEY_GET_URL_PACKAGE_SUCCESS_ADMIN,
                  error: SEND_KEY_GET_URL_PACKAGE_ERROR_ADMIN
            },
            "GET",
            data,
            "FETCH_DATA",
            "admin"
      )
}
