import React, { Component, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import Routeradmin from './admin'
import Routercustomer from './customer'
import Routerlawyer from './lawyer'

import("../css/main.css")

export default class index extends Component {
    render() {
        const { pathname } = this.props.location
        /*-------------------------------*/
        // check pathname for show which one style sheet
        const isAdmin = pathname.indexOf('/admin') > -1
        const isCustomer = pathname.indexOf('/') > -1
        const isLawyer = pathname.indexOf('/lawyer') > -1
        /*------------------------------*/
        return (
            <Suspense fallback={<div />}>
                <Switch>
                    <Route path="/admin">
                        <Routeradmin isAdmin={isAdmin} />
                    </Route>
                    <Route path="/lawyer">
                        <Routerlawyer isLawyer={isLawyer} />
                    </Route> 
                    <Route path="/">
                        <Routercustomer isCustomer={isCustomer} />
                    </Route>
                </Switch>
            </Suspense>
        )
    }
}
