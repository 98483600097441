import React from "react"
import { Route, Redirect, Switch } from "react-router-dom"
import { connect } from "react-redux"
import { SidebarAdmin } from "../../components/admin"

import {
	DashBoardAdmin,
	UserProfileAdmin,
	AddAdminAdmin,
	ViewAdminAdmin,
	EditAdminAdmin,
	AllAdminAdmin,
	AllPackageAdmin,
	EditPackageAdmin,
	ViewPackgeAdmin,
	AllOrder,
	OrderDetail,
	AllTaskReport,
	AllTaskReviewReport,
	AllTaskContract,
	AllTaskReviewContract,
	AllTaskTransfer,
	// TaskAdmin,
	LawyerHistoryAdmin,
	AllLawyerAdmin,
	EditLawyerAdmin,
	ViewLawyerAdmin,
	CustomerHistoryAdmin,
	AllCustomerAdmin,
	EditCustomerAdmin,
	ViewCustomerAdmin,
	TaskReport,
	TaskContract,
	TaskTransfer,
	TaskReviewReport,
	TaskReviewContract,
	TaskLawyer,
	AdvanceSearchTask,
	AdvanceSearchOrder,
	SearchedTask,
	SearchedOrder,
	AllOrderCustom,
	OrderCustomDetail
} from "../../views"

const PrivateRouterAdmin = ({ component: Component, isAuth, ...rest }) => {
	if (isAuth) {
		return (
			<div className='kit' style={{ height: "100vh" }}>
				<SidebarAdmin />
				<div className='kit-wrapper'>
					<div className='main'>
						<div className='content'>
							<Switch>
								<Route
									exact
									path='/admin/dashboard'
									component={DashBoardAdmin}
								/>
								<Route
									exact
									path='/admin'
									component={UserProfileAdmin}
								/>
								<Route
									exact
									path='/admin/user/addadmin'
									component={AddAdminAdmin}
								/>
								<Route
									exact
									path='/admin/user/viewadmin/:id'
									component={ViewAdminAdmin}
								/>
								<Route
									exact
									path='/admin/user/editadmin/:id'
									component={EditAdminAdmin}
								/>
								<Route
									exact
									path='/admin/user/admin'
									component={AllAdminAdmin}
								/>
								<Route
									exact
									path='/admin/packages'
									component={AllPackageAdmin}
								/>
								<Route
									exact
									path='/admin/packages/editpackage/:id'
									component={EditPackageAdmin}
								/>
								<Route
									exact
									path='/admin/packages/viewpackage/:id'
									component={ViewPackgeAdmin}
								/>
								<Route
									exact
									path='/admin/orders'
									component={AllOrder}
								/>
								<Route
									exact
									path='/admin/orders/orderdetail/:id'
									component={OrderDetail}
								/>
								<Route
									exact
									path='/admin/tasks/report'
									component={AllTaskReport}
								/>
								<Route
									exact
									path='/admin/tasks/contract'
									component={AllTaskContract}
								/>
								<Route
									exact
									path='/admin/tasks/transfer'
									component={AllTaskTransfer}
								/>
								<Route
									exact
									path='/admin/tasks/review-report'
									component={AllTaskReviewReport}
								/>
								<Route
									exact
									path='/admin/tasks/review-contract'
									component={AllTaskReviewContract}
								/>
								{/* <Route
									exact
									path='/admin/tasks/task/:id'
									component={TaskAdmin}
								/> */}
								<Route
									exact
									path='/admin/user/viewlawyer/:id'
									component={ViewLawyerAdmin}
								/>
								<Route
									exact
									path='/admin/user/editlawyer/:id'
									component={EditLawyerAdmin}
								/>
								<Route
									exact
									path='/admin/user/lawyerhistory/:id'
									component={LawyerHistoryAdmin}
								/>
								<Route
									exact
									path='/admin/user/lawyer'
									component={AllLawyerAdmin}
								/>
								<Route
									exact
									path='/admin/user/viewcustomer/:id'
									component={ViewCustomerAdmin}
								/>
								<Route
									exact
									path='/admin/user/editcustomer/:id'
									component={EditCustomerAdmin}
								/>
								<Route
									exact
									path='/admin/user/customer'
									component={AllCustomerAdmin}
								/>
								<Route
									exact
									path='/admin/user/customerhistory/:id'
									component={CustomerHistoryAdmin}
								/>
								<Route
									exact
									path='/admin/tasks/report/:id'
									component={TaskReport}
								/>
								<Route
									exact
									path='/admin/tasks/contract/:id'
									component={TaskContract}
								/>
								<Route
									exact
									path='/admin/tasks/transfer/:id'
									component={TaskTransfer}
								/>
								<Route
									exact
									path='/admin/tasks/review-report/:id'
									component={TaskReviewReport}
								/>
								<Route
									exact
									path='/admin/tasks/review-contract/:id'
									component={TaskReviewContract}
								/>
								<Route
									exact
									path='/admin/tasks/task/:id'
									component={TaskLawyer}
								/>
								<Route
									exact
									path='/admin/tasks/search'
									component={AdvanceSearchTask}
								/>
								<Route
									exact
									path='/admin/tasks/searched'
									component={SearchedTask}
								/>
								<Route
									exact
									path='/admin/orders/search'
									component={AdvanceSearchOrder}
								/>
								<Route
									exact
									path='/admin/orders/searched'
									component={SearchedOrder}
								/>
								<Route
									exact
									path='/admin/orders/custom'
									component={AllOrderCustom}
								/>
								<Route
									exact
									path='/admin/ordercustom/:id'
									component={OrderCustomDetail}
								/>
								<Route
									render={() => (
										<div className='lawyer-404'>
											<span>
												404
												<small>Page not found.</small>
											</span>
										</div>
									)}
								/>
							</Switch>
						</div>
					</div>
				</div>
			</div>
		)
	} else {
		return <Redirect to='/admin/login' />
	}
}

const mapStateToProps = ({ AuthAdmin }) => ({ ...AuthAdmin })

export default connect(mapStateToProps)(PrivateRouterAdmin)
