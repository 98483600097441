
import token from '../../service/token'
import api from "../../service/api"
export const CHANGE_STORE_VALUE_CUSTOMER = "CHANGE_STORE_VALUE_CUSTOMER"

export const UPLOADPROFILE_SUCCESS = 'UPLOADPROFILE_SUCCESS'
export const UPLOADPROFILE_ERROR = 'UPLOADPROFILE_ERROR'

export const initialstate = {
    name: "",
    isRegister: false,
    isAuth: !!token.getToken("customer"),
    isChangePassword: !!token.getToken("customer"),

}

const uploadprofileAction = {
    success: UPLOADPROFILE_SUCCESS,
    error: UPLOADPROFILE_ERROR
}

export default function (state = initialstate, action) {
    switch (action.type) {
        case UPLOADPROFILE_SUCCESS:
          
            return {
                ...state,
                isAuth: false,
               
            }
        case UPLOADPROFILE_ERROR:

            return {
                ...state,
                isAuth: false,
                
            }
        default:
            return state
    }
}


export const uploadprofile = (data) => {
    delete data.uc_email
    return api(
        "/api/customer/"+data.id,
        uploadprofileAction,
        "PUT",
        data
    )
}
