import api from "../../service/api"
import { ORDER_PACKAGE_ERROR } from "../customer/package"

export const FETCH_ORDERS_SUCCESS_ADMIN = "FETCH_ORDERS_SUCCESS_ADMIN"
export const FETCH_ORDERS_ERROR_ADMIN = "FETCH_ORDERS_ERROR_ADMIN"

export const FETCH_ORDER_SUCCESS_ADMIN = "FETCH_ORDER_SUCCESS_ADMIN"
export const FETCH_ORDER_ERROR_ADMIN = "FETCH_ORDER_ERROR_ADMIN"

export const CANCEL_ORDER_SUCCESS_ADMIN = "CANCEL_ORDER_SUCCESS_ADMIN"
export const CANCEL_ORDER_ERROR_ADMIN = "CANCEL_ORDER_ERROR_ADMIN"

export const CLEAR_VALUE_IN_STORE_ORDER_ADMIN =
	"CLEAR_VALUE_IN_STORE_ORDER_ADMIN"

export const HANDLE_INPUT_SEARCH_ORDER_ADMIN = "HANDLE_INPUT_SEARCH_ORDER_ADMIN"

export const SEARCH_ORDER_SUCCESS_ADMIN = "SEARCH_ORDER_SUCCESS_ADMIN"
export const SEARCH_ORDER_ERROR_ADMIN = "SEARCH_ORDER_ERROR_ADMIN"

export const SET_VALUE_ORDER_ADMIN = "SET_VALUE_ORDER_ADMIN"

export const ORDER_CUSTOM_SUCCESS = "ORDER_CUSTOM_SUCCESS"
export const ORDER_CUSTOM_ERROR = "ORDER_CUSTOM_ERROR"


export const CUSTOM_PRICE_SUCCESS = "CUSTOM_PRICE_SUCCESS"
export const CUSTOM_PRICE_ERROR = "CUSTOM_PRICE_ERROR"

export const CHANGE_VALUE_IN_STORE_ORDER = "CHANGE_VALUE_IN_STORE_ORDER"

export const CUSTOM_DESCRIPTION_SUCCESS = "CUSTOM_DESCRIPTION_SUCCESS"
export const CUSTOM_DESCRIPTION_ERROR = "CUSTOM_DESCRIPTION_ERROR"

export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS"
export const DELETE_ORDER_ERROR = "DELETE_ORDER_ERROR"
export const initialstate = {
	orders: [],
	order: {
		id: "",
		od_code: "",
		od_price: "",
		packageId: "",
		provinceId: "",
		userCustomerId: "",
		od_status: "",
		updatedBy: "",
		createdAt: "",
		updatedAt: "",
		deletedAt: "",
		tasks: [],
		user_customer: {
			id: null,
			uc_firstname: "",
			uc_lastname: ""
		}
	},
	
	search: {
		od_code: "",
		pv_name: "",
		uc_email: "",
		uc_firstname: "",
		uc_lastname: ""
	},
	isCancel: false,
	isLoading: true,
	isSearching: false,
	isCustomPriceSuccess:false,
	isUpdateDescription:false
}

export default (state = initialstate, action) => {
	switch (action.type) {
		case FETCH_ORDERS_SUCCESS_ADMIN:
			return {
				...state,
				orders: [...action.payload.data],
				isLoading: false
			}
		case FETCH_ORDERS_ERROR_ADMIN:
			return {
				...state,
				isLoading: false
			}
		case CLEAR_VALUE_IN_STORE_ORDER_ADMIN:
			return {
				...initialstate
			}
		case FETCH_ORDER_SUCCESS_ADMIN:
		
			return {
				...state,
				order: {
					...action.payload.data
				},
				isLoading: false
			}
		case FETCH_ORDER_ERROR_ADMIN:
			return {
				...state,
				order: {
					...initialstate.order
				},
				isLoading: false
			}
		case CANCEL_ORDER_SUCCESS_ADMIN:
			return {
				...state,
				isCancel: true
			}
		case CANCEL_ORDER_ERROR_ADMIN:
			return {
				...state,
				isCancel: false
			}
		case HANDLE_INPUT_SEARCH_ORDER_ADMIN:
			return {
				...state,
				search: {
					...state.search,
					[action.payload.name]: action.payload.value
				}
			}
		case SEARCH_ORDER_SUCCESS_ADMIN:
			return {
				...state,
				orders: [...state.orders, ...action.payload.data]
			}
		case SEARCH_ORDER_ERROR_ADMIN:
			return {
				...state,
				orders: [...state.orders]
			}
		case SET_VALUE_ORDER_ADMIN:
			return {
				...state,
				[action.payload.name]: action.payload.value
			}
		case ORDER_CUSTOM_SUCCESS:
			return {
				...state,
				orders: [...action.payload.data]
			}
			case CHANGE_VALUE_IN_STORE_ORDER: {
				return {
					  ...state,
					  order: {
							...state.order,
							[action.payload.name]: action.payload.value
					  }
				}
		  }
		case  CUSTOM_PRICE_SUCCESS:{
			return {
				...state,
				isCustomPriceSuccess: true
			}
		}

		case  CUSTOM_PRICE_ERROR:{
			return {
				...state,
				isCustomPriceSuccess: false
			}
		}

		case CUSTOM_DESCRIPTION_SUCCESS:{
			return {
				...state,
				isUpdateDescription:true
			}
		}
		case CUSTOM_DESCRIPTION_ERROR:{
			return {
				...state,
				isUpdateDescription:false
			}
		}
		default:
			return state
	}
}

export const fetchOrders = () => {
	return api(
		"/api/order",
		{
			success: FETCH_ORDERS_SUCCESS_ADMIN,
			error: FETCH_ORDERS_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const fetchOrder = id => {
	return api(
		`/api/order/${id}`,
		{
			success: FETCH_ORDER_SUCCESS_ADMIN,
			error: FETCH_ORDER_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const cancelOrder = (id, data) => {
	return api(
		`/api/order/status/${id}`,
		{
			success: CANCEL_ORDER_SUCCESS_ADMIN,
			error: CANCEL_ORDER_ERROR_ADMIN
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const clearValueInStoreOrder = () => {
	return {
		type: CLEAR_VALUE_IN_STORE_ORDER_ADMIN
	}
}

export const handleInputSearchOrder = ({ target }) => {
	return {
		type: HANDLE_INPUT_SEARCH_ORDER_ADMIN,
		payload: {
			name: target.name,
			value: target.value
		}
	}
}

export const searchOrder = (data, start) => {
	return api(
		`/api/order/search?start=${start}`,
		{
			success: SEARCH_ORDER_SUCCESS_ADMIN,
			error: SEARCH_ORDER_ERROR_ADMIN
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const setValueOrder = data => {
	return {
		type: SET_VALUE_ORDER_ADMIN,
		payload: {
			name: data.target.name,
			value: data.target.value
		}
	}
}

export const fetchOrderCustom = id => {
	return api(
		`/api/order/custom`,
		{
			success: ORDER_CUSTOM_SUCCESS,
			error: ORDER_CUSTOM_ERROR
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}
export const changeValueInStore = ({ target }) => {
	return {
		  type: CHANGE_VALUE_IN_STORE_ORDER,
		  payload: {
				name: target.name,
				value: target.value
		  }
	}
}

export const customPrice = (id,data) => {
	return api(
		`/api/order/custom/${id}`,
		{
			success: CUSTOM_PRICE_SUCCESS,
			error: CUSTOM_PRICE_ERROR
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const customDescription = (id,data) => {
	return api(
		`/api/order/description/${id}`,
		{
			success: CUSTOM_DESCRIPTION_SUCCESS,
			error: CUSTOM_DESCRIPTION_ERROR
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const deleteOrder = (id,data) => {
	return api(
		`/api/order/${id}`,
		{
			success: DELETE_ORDER_SUCCESS,
			error: DELETE_ORDER_ERROR
		},
		"DELETE",
		{},
		"FETCH_DATA",
		"admin"
	)
}
