import api from "../../service/api"

export const SEND_NEW_ADMIN_SUCCESS_ADMIN = "SEND_NEW_ADMIN_SUCCESS_ADMIN"
export const SEND_NEW_ADMIN_ERROR_ADMIN = "SEND_NEW_ADMIN_ERROR_ADMIN"

export const FETCH_ADMINS_SUCCESS_ADMIN = "FETCH_ADMINS_SUCCESS_ADMIN"
export const FETCH_ADMINS_ERROR_ADMIN = "FETCH_ADMINS_ERROR_ADMIN"

export const FETCH_ADMIN_SUCCESS_ADMIN = "FETCH_ADMIN_SUCCESS_ADMIN"
export const FETCH_ADMIN_ERROR_ADMIN = "FETCH_ADMIN_ERROR_ADMIN"

export const CHANGE_STORE_ADMIN_ADMIN = "CHANGE_STORE_ADMIN_ADMIN"

export const UPDATE_ADMIN_SUCCESS_ADMIN = "UPDATE_ADMIN_SUCCESS_ADMIN"
export const UPDATE_ADMIN_ERROR_ADMIN = "UPDATE_ADMIN_ERROR_ADMIN"

export const CLEAR_STORE_ADMIN_ADMIN = "CLEAR_STORE_ADMIN_ADMIN"

export const CHANGE_STATUS_ADMIN_SUCCESS_ADMIN =
	"CHANGE_STATUS_ADMIN_SUCCESS_ADMIN"
export const CHANGE_STATUS_ADMIN_ERROR_ADMIN = "CHANGE_STATUS_ADMIN_ERROR_ADMIN"

export const SET_OTHER_ADMIN_PASSWORD_SUCCESS_ADMIN =
	"SET_OTHER_ADMIN_PASSWORD_SUCCESS_ADMIN"
export const SET_OTHER_ADMIN_PASSWORD_ERROR_ADMIN =
	"SET_OTHER_ADMIN_PASSWORD_ERROR_ADMIN"

export const DELETE_ADMIN_SUCCESS_ADMIN = "DELETE_ADMIN_SUCCESS_ADMIN"
export const DELETE_ADMIN_ERROR_ADMIN = "DELETE_ADMIN_ERROR_ADMIN"

export const initialstate = {
	admins: [],
	admin: {
		ad_firstname: "",
		ad_lastname: "",
		ad_email: "",
		ad_password: "",
		ad_tel: "",
		ad_status: ""
	},
	isNewAdminSent: false,
	isAdminUpdated: false,
	isLoading: true,
	isStatusUpdated: false,
	isOtherAdminPasswordSet: false,
	isAdminDeleted: false,
	errorMessageAddAdmin: ""
}

export default (state = initialstate, action) => {
	switch (action.type) {
		case CHANGE_STORE_ADMIN_ADMIN:
			return {
				...state,
				admin: {
					...state.admin,
					[action.payload.name]: action.payload.value
				}
			}
		case SEND_NEW_ADMIN_SUCCESS_ADMIN:
			return {
				...state,
				isNewAdminSent: true
			}
		case SEND_NEW_ADMIN_ERROR_ADMIN:
			return {
				...state,
				isNewAdminSent: false,
				errorMessageAddAdmin:
					action.payload &&
					action.payload.data &&
					action.payload.data.error &&
					action.payload.data.error.errors &&
					action.payload.data.error.errors[0] &&
					action.payload.data.error.errors[0].message
						? action.payload.data.error.errors[0].message
						: ""
			}
		case FETCH_ADMIN_SUCCESS_ADMIN:
			return {
				...state,
				admin: {
					...state.admin,
					...action.payload.data
				}
			}
		case FETCH_ADMINS_SUCCESS_ADMIN:
			return {
				...state,
				admins: [...action.payload.data],
				isLoading: false
			}
		case FETCH_ADMINS_ERROR_ADMIN:
			return {
				...state,
				isLoading: false
			}
		case UPDATE_ADMIN_SUCCESS_ADMIN:
			return {
				...state,
				isAdminUpdated: true
			}
		case UPDATE_ADMIN_ERROR_ADMIN:
			return {
				...state,
				isAdminUpdated: false
			}
		case CLEAR_STORE_ADMIN_ADMIN:
			return {
				...initialstate
			}
		case CHANGE_STATUS_ADMIN_SUCCESS_ADMIN:
			return {
				...state,
				isStatusUpdated: true,
				admin: {
					...state.admin,
					ad_status: !state.admin.ad_status
				}
			}
		case CHANGE_STATUS_ADMIN_ERROR_ADMIN:
			return {
				...state,
				isStatusUpdated: false
			}
		case SET_OTHER_ADMIN_PASSWORD_SUCCESS_ADMIN:
			return {
				...state,
				isOtherAdminPasswordSet: true
			}
		case SET_OTHER_ADMIN_PASSWORD_ERROR_ADMIN:
			return {
				...state,
				isOtherAdminPasswordSet: false
			}
		case DELETE_ADMIN_SUCCESS_ADMIN:
			return {
				...state,
				isAdminDeleted: true
			}
		case DELETE_ADMIN_ERROR_ADMIN:
			return {
				...state,
				isAdminDeleted: false
			}
		case "DUMMY_ADMINS":
			return {
				...state,
				admins: action.payload
			}
		default:
			return state
	}
}

export const sendNewAdmin = data => {
	return api(
		"/auth/signup/admin",
		{
			success: SEND_NEW_ADMIN_SUCCESS_ADMIN,
			error: SEND_NEW_ADMIN_ERROR_ADMIN
		},
		"POST",
		data
	)
} // addadmin

export const fetchAdmins = () => {
	return api("/api/admin", {
		success: FETCH_ADMINS_SUCCESS_ADMIN,
		error: FETCH_ADMINS_ERROR_ADMIN
	})
}

export const fetchAdmin = id => {
	return api(
		`/api/admin/${id}`,
		{
			success: FETCH_ADMIN_SUCCESS_ADMIN,
			error: FETCH_ADMIN_ERROR_ADMIN
		},
		"GET",
		id
	)
}

export const changeStoreAdmin = ({ target }) => {
	return {
		type: CHANGE_STORE_ADMIN_ADMIN,
		payload: {
			name: target.name,
			value: target.value
		}
	}
}

export const updateAdmin = (id, data) => {
	return api(
		`/api/admin/${id}`,
		{
			success: UPDATE_ADMIN_SUCCESS_ADMIN,
			error: UPDATE_ADMIN_ERROR_ADMIN
		},
		"PUT",
		data
	)
} // editadmin

export const clearStoreAdmin = () => {
	return {
		type: CLEAR_STORE_ADMIN_ADMIN
	}
}

export const changeStatusAdmin = (id, data) => {
	return api(
		`/api/admin/status/${id}`,
		{
			success: CHANGE_STATUS_ADMIN_SUCCESS_ADMIN,
			error: CHANGE_STATUS_ADMIN_ERROR_ADMIN
		},
		"PUT",
		data
	)
}

export const setOtherAdminPassword = (id, data) => {
	return api(
		`/api/admin/password/${id}`,
		{
			success: SET_OTHER_ADMIN_PASSWORD_SUCCESS_ADMIN,
			error: SET_OTHER_ADMIN_PASSWORD_ERROR_ADMIN
		},
		"PUT",
		data
	)
}

export const deleteAdmin = id => {
	return api(
		`/api/admin/${id}`,
		{
			success: DELETE_ADMIN_SUCCESS_ADMIN,
			error: DELETE_ADMIN_ERROR_ADMIN
		},
		"DELETE"
	)
}

export const dummyAdmis = () => {
	return {
		type: "DUMMY_ADMINS",
		payload: [
			{
				id: 1,
				NAME: 2,
				EMAIL: "2",
				TEL: 3,
				"CREATED AT": "date",
				"UPDATED AT": "date",
				admin_status: true
			},
			{
				id: 2,
				NAME: 1,
				EMAIL: "2",
				TEL: 3,
				"CREATED AT": "date",
				"UPDATED AT": "date",
				admin_status: true
			},
			{
				id: 3,
				NAME: 1,
				EMAIL: "2",
				TEL: 3,
				"CREATED AT": "date",
				"UPDATED AT": "date",
				admin_status: true
			},
			{
				id: 4,
				NAME: 1,
				EMAIL: "2",
				TEL: 3,
				"CREATED AT": "date",
				"UPDATED AT": "date",
				admin_status: true
			},
			{
				id: 5,
				NAME: 1,
				EMAIL: "2",
				TEL: 3,
				"CREATED AT": "date",
				"UPDATED AT": "date",
				admin_status: false
			}
		]
	}
}
