import config from "../config";

export default (
    url = "/",
    actiontype = {},
    method = "GET",
    body = {},
    type = "FETCH_DATA",
    tokentype = ""
  ) =>
  (dispatch) =>
    dispatch({
      type,
      actiontype,
      fetch: {
        url: `${config.host}${url}`,
        method,
        body,
      },
      tokentype,
    });
