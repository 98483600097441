import api from "../../service/api"
export const ORDER_TASK_SUCCESS = "ORDER_TASK_SUCCESS"
export const ORDER_TASK_ERROR = "ORDER_TASK_ERROR"

export const ORDER_TASK_WORKING_SUCCESS = "ORDER_TASK_WORKING_SUCCESS"
export const ORDER_TASK_WORKING_ERROR = "ORDER_TASK_WORKING_ERROR"

export const ORDER_TASK_ACCEPTING_SUCCESS = "ORDER_TASK_ACCEPTING_SUCCESS"
export const ORDER_TASK_ACCEPTING_ERROR = "ORDER_TASK_ACCEPTING_ERROR"

export const SUBMIT_TASK_SUCCESS = "SUBMIT_TASK_SUCCESS"
export const SUBMIT_TASK_ERROR = "SUBMIT_TASK_ERROR"

export const GET_TASK_FILE_SUCCESS = "GET_TASK_FILE_SUCCESS"
export const GET_TASK_FILE_ERROR = "GET_TASK_FILE_ERROR"

export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS"
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR"

export const PUSH_KEY_FILE_SUCCESS = "PUSH_KEY_FILE_SUCCESS"
export const PUSH_KEY_FILE_ERROR = "PUSH_KEY_FILE_ERROR"

export const DOWNLOAD_TASK_FILE_SUCCESSS = "DOWNLOAD_TASK_FILE_SUCCESSS"
export const DOWNLOAD_TASK_FILE_ERROR = "DOWNLOAD_TASK_FILE_ERROR"

export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS"
export const DELETE_FILE_ERROR = "DELETE_FILE_ERROR"

export const HISTORY_FILE_SUCCESS = "HISTORY_FILE_SUCCESS"
export const HISTORY_FILE_ERROR = "HISTORY_FILE_ERROR"

export const DONWLOAD_SUCCESS = "DONWLOAD_SUCCESS"
export const DOWNLOAD_ERROR = "DOWNLOAD_ERROR"
export const REF_SUCCESS = "REF_SUCCESS"
export const REF_ERROR = "REF_ERROR"

export const CLEAR_TASK = "CLEAR_TASK"

export const CLEAR_HISTORY_LAWYER_TASK_UNMOUNT =
	"CLEAR_HISTORY_LAWYER_TASK_UNMOUNT"
export const initialstate = {
	taskorder: [],
	taskaccept: [],
	messagerroraccept: "",
	filetaskinprocess: {},
	fileafterupload: [],
	linkfile: "",
	historytaskdata: [],
	reference: []
}
export default function(state = initialstate, action) {
	switch (action.type) {
		case ORDER_TASK_SUCCESS: {
			return {
				...state,
				taskorder: action.payload.data
			}
		}
		case ORDER_TASK_ERROR: {
			return {
				...state
			}
		}
		case ORDER_TASK_WORKING_SUCCESS:
			return {
				...state,
				taskaccept: action.payload.data
			}

		case ORDER_TASK_WORKING_ERROR:
			return {
				...state
			}
		case ORDER_TASK_ACCEPTING_SUCCESS:
			return {
				...state
			}
		case ORDER_TASK_ACCEPTING_ERROR:
			return {
				...state,
				messagerroraccept: true
			}
		case SUBMIT_TASK_SUCCESS:
			return {
				...state
			}
		case SUBMIT_TASK_ERROR:
			return {
				...state
			}
		case GET_TASK_FILE_SUCCESS:
			return {
				...state,
				filetaskinprocess: action.payload.data
			}
		case UPLOAD_FILE_SUCCESS:
			localStorage.setItem("uploadSuccess", true)
			return {
				...state,
				fileafterupload: [...action.payload.data]
			}
		case UPLOAD_FILE_ERROR:
			localStorage.setItem("uploadSuccess", false)
			return {
				...state
			}
		case PUSH_KEY_FILE_SUCCESS:
			return {
				...state
			}
		case DOWNLOAD_TASK_FILE_SUCCESSS:
			return {
				...state,
				linkfile: action.payload.data
			}
		case HISTORY_FILE_SUCCESS:
			return {
				...state,
				historytaskdata: [
					...state.historytaskdata,
					...action.payload.data
				]
			}
		case REF_SUCCESS:
			return {
				...state,
				reference: action.payload.data
			}
		case REF_ERROR:
			return {
				...state
			}
		case CLEAR_TASK:
			return {
				...state,
				...initialstate
			}
		case DONWLOAD_SUCCESS:
			return {
				...state
			}
		case CLEAR_HISTORY_LAWYER_TASK_UNMOUNT:
			return {
				...state,
				historytaskdata: []
			}
		default:
			return state
	}
}

export const fetchtask = () => {
	return api(
		"/api/task",
		{
			success: ORDER_TASK_SUCCESS,
			error: ORDER_TASK_ERROR
		},
		"GET",
		"",
		"FETCH_DATA",
		"lawyer"
	)
}
export const fetchtaskaccept = () => {
	return api(
		"/api/task/lawyer/working",
		{
			success: ORDER_TASK_WORKING_SUCCESS,
			error: ORDER_TASK_WORKING_ERROR
		},
		"GET",
		"",
		"FETCH_DATA",
		"lawyer"
	)
}

export const accepttask = data => {
	return api(
		"/api/task/accept/" + data,
		{
			success: ORDER_TASK_ACCEPTING_SUCCESS,
			error: ORDER_TASK_ACCEPTING_ERROR
		},
		"PUT",
		"",
		"FETCH_DATA",
		"lawyer"
	)
}
export const fetchmytaskafteraccept = () => {
	return api(
		"/api/task/lawyer/working",
		{
			success: ORDER_TASK_WORKING_SUCCESS,
			error: ORDER_TASK_WORKING_ERROR
		},
		"GET",
		"",
		"FETCH_DATA",
		"lawyer"
	)
}
export const submittask = id => dispatch => {
	return api(
		"/api/task/submit/" + id,
		{
			success: SUBMIT_TASK_SUCCESS,
			error: SUBMIT_TASK_ERROR
		},
		"PUT",
		"",
		"FETCH_DATA",
		"lawyer"
	)(dispatch)
}

export const taskfileinprocess = id => {
	return api(
		"/api/task/" + id,
		{
			success: GET_TASK_FILE_SUCCESS,
			error: GET_TASK_FILE_ERROR
		},
		"GET",
		"",
		"FETCH_DATA",
		"lawyer"
	)
}
export const downloadfile = file => {
	return api(
		"/api/upload/task/" + file,
		{
			success: DOWNLOAD_TASK_FILE_SUCCESSS,
			error: DOWNLOAD_TASK_FILE_ERROR
		},
		"GET",
		"",
		"FETCH_DATA",
		"lawyer"
	)
}

export const uploadfile = (id, type) =>
	api(
		"/api/upload/task/" + id,
		{
			success: UPLOAD_FILE_SUCCESS,
			error: UPLOAD_FILE_ERROR
		},
		"POST",
		type,
		"FETCH_DATA",
		"lawyer"
	)

export const pushkeyfile = (id, file_url) => dispatch => {
	return api(
		"/api/file/" + id,
		{
			success: PUSH_KEY_FILE_SUCCESS,
			error: PUSH_KEY_FILE_ERROR
		},
		"POST",
		file_url,
		"FETCH_DATA",
		"lawyer"
	)(dispatch)
}
export const deletefiletask = id => {
	return api(
		"/api/file/" + id,
		{
			success: DELETE_FILE_SUCCESS,
			error: DELETE_FILE_ERROR
		},
		"DELETE",
		"",
		"FETCH_DATA",
		"lawyer"
	)
}

export const historytask = (start, limit) => {
	return api(
		`/api/task/lawyer/history?start=${start}&limit=${limit}`,
		{
			success: HISTORY_FILE_SUCCESS,
			error: HISTORY_FILE_ERROR
		},
		"GET",
		"",
		"FETCH_DATA",
		"lawyer"
	)
}

export const referencetask = id => {
	return api(
		"/api/task/reference/" + id,
		{
			success: REF_SUCCESS,
			error: REF_ERROR
		},
		"GET",
		"",
		"FETCH_DATA",
		"lawyer"
	)
}
export const download = key => {
	return api(
		"/api/upload/download/" + key,
		{
			success: DONWLOAD_SUCCESS,
			error: DOWNLOAD_ERROR
		},
		"GET",
		"",
		"FETCH_DATA",
		"lawyer"
	)
}

export const cleartask = id => {
	return { type: CLEAR_TASK }
}

export const clearHistory = () => {
	return {
		type: CLEAR_HISTORY_LAWYER_TASK_UNMOUNT
	}
}
