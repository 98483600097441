import jwt from "jwt-simple";
import axios from "axios";

import config from "../config";
const secretToken = "slawyer";
const time = 6; //อายุ token admin (วัน)
// const timeout = 7 //อายุ token customer (วัน)
// const timeouting = 8 //อายุ token lawyer (วัน)

const manageToken = {
  getToken(type = "customer") {
    const token = localStorage.getItem(`token${type}`) || null;
    let decode = null;

    if (token && type === "admin") {
      try {
        decode = jwt.decode(token, secretToken, false);
        if (decode.exp < new Date().getTime()) {
          this.redirectToLogin(type);
          // console.log("expired")
        } else if (decode.exp - new Date().getTime() <= 24 * 60 * 60 * 1000) {
          // console.log("refresh token")
          axios
            .request({
              method: "GET",
              url: `${config.host}/auth/refresh`,
              data: {},
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${decode.token}`,
              },
            })
            .then((res) => {
              this.removeToken(type);
              this.setToken(res.data.data, type);
              decode = jwt.decode(
                localStorage.getItem(`token${type}`) || null,
                secretToken,
                false
              );
            })
            .catch((err) => {
              console.log(err);
            });
          return decode.token || null;
        } else {
          return decode.token || null;
          // console.log("ok")
        }
        // return decode.exp >= new Date().getTime()
        // 	? decode.token || null
        // 	: this.redirectToLogin(type)
      } catch (error) {
        this.redirectToLogin(type);
        return null;
      }
    } else if (token && type === "customer") {
      try {
        decode = jwt.decode(token, secretToken, false);
        if (decode.exp < new Date().getTime()) {
          this.redirectToLogin(type);
          // console.log("expired")
        } else if (decode.exp - new Date().getTime() <= 24 * 60 * 60 * 1000) {
          // console.log("one day")
          axios
            .request({
              method: "GET",
              url: `${config.host}/auth/refresh`,
              data: {},
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${decode.token}`,
              },
            })
            .then((res) => {
              this.removeToken(type);
              this.setToken(res.data.data, type);
              decode = jwt.decode(
                localStorage.getItem(`token${type}`) || null,
                secretToken,
                false
              );
            })
            .catch((err) => {});
          return decode.token || null;
        } else {
          return decode.token || null;
          // console.log("ok")
        }
      } catch (error) {
        this.redirectToLogin(type);
        return null;
      }
    } else if (token && type === "lawyer") {
      try {
        decode = jwt.decode(token, secretToken, false);
        if (decode.exp < new Date().getTime()) {
          this.redirectToLogin(type);
          // console.log("expired")
        } else if (decode.exp - new Date().getTime() <= 24 * 60 * 60 * 1000) {
          // console.log("one day")
          axios
            .request({
              method: "GET",
              url: `${config.host}/auth/refresh`,
              data: {},
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${decode.token}`,
              },
            })
            .then((res) => {
              this.removeToken(type);
              this.setToken(res.data.data, type);
              decode = jwt.decode(
                localStorage.getItem(`token${type}`) || null,
                secretToken,
                false
              );
            })
            .catch((err) => {
              console.log(err);
            });
          return decode.token || null;
        } else {
          return decode.token || null;
          // console.log("ok")
        }
      } catch (error) {
        this.redirectToLogin(type);
        return null;
      }
    }
    return null;
  },
  setToken(token, type = "customer") {
    localStorage.setItem(`token${type}`, this.encodeToken(token));
  },
  // setTimeExp() {
  //     localStorage.setItem('exp', new Date().getTime() + timeout * 24 * 60 * 60 * 1000)
  // },
  decodeToken(type = "customer") {
    const token = this.getToken(type);
    if (token) return jwt.decode(token, "", true);
    return null;
  },
  encodeToken(token) {
    const iat = new Date().getTime();
    return jwt.encode(
      { token, iat, exp: iat + time * 24 * 60 * 60 * 1000 },
      secretToken
    );
  },
  removeToken(type = "customer") {
    localStorage.removeItem(`token${type}`);
  },
  redirectToLogin(type = "customer") {
    this.removeToken(type);
    if (type === "customer") {
      document.location.href = `${window.location.protocol}//${window.location.host}/property/login`;
    } else if (type === "admin") {
      document.location.href = `${window.location.protocol}//${window.location.host}/admin/login`;
    } else if (type === "lawyer") {
      document.location.href = `${window.location.protocol}//${window.location.host}/lawyer/login`;
    }
    return null;
  },
};

export default manageToken;
