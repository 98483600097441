import api from "../../service/api"

export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS"
export const FETCH_ORDER_ERROR = "FETCH_ORDER_ERROR"
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS"
export const FETCH_ORDERS_ERROR = "FETCH_ORDERS_ERROR"
export const CHECK_CHARGE_SUCCESS = "CHECK_CHARGE_SUCCESS"
export const CHECK_CHARGE_ERROR = "CHECK_CHARGE_ERROR"
export const DOWNLOAD_TASK_FILE_SUCCESSS = "DOWNLOAD_TASK_FILE_SUCCESSS"
export const DOWNLOAD_TASK_FILE_ERROR = "DOWNLOAD_TASK_FILE_ERROR"
export const initialstate = {
	order: null,
	orders: [],
	linkfilecustomer: ""
}

const fetchOrderAction = {
	success: FETCH_ORDER_SUCCESS,
	error: FETCH_ORDER_ERROR
}
const fetchOrdersAction = {
	success: FETCH_ORDERS_SUCCESS,
	error: FETCH_ORDERS_ERROR
}
const checkChargeAction = {
	success: CHECK_CHARGE_SUCCESS,
	error: CHECK_CHARGE_ERROR
}

export default function(state = initialstate, action) {
	switch (action.type) {
		case FETCH_ORDER_SUCCESS: {
			return {
				...state,
				order: action.payload.data
			}
		}
		case FETCH_ORDERS_SUCCESS: {
			return {
				...state,
				orders: action.payload.data
			}
		}
		case DOWNLOAD_TASK_FILE_SUCCESSS:
			return {
				...state,
				linkfilecustomer: action.payload.data
			}
		case CHECK_CHARGE_SUCCESS: {
			return {
				status: 200
			}
		}
		default:
			return state
	}
}
export const fetchOrder = data => {
	return api(`/api/order/${data}`, fetchOrderAction, "GET")
}
export const fetchOrders = () => {
	return api("/api/order/customer", fetchOrdersAction, "GET")
}
export const checkCharge = data => {
	return api(`/api/omise/checkcharge/${data}`, checkChargeAction, "GET")
}
export const downloadfile = file => {
	return api(
		"/api/upload/task/" + file,
		{
			success: DOWNLOAD_TASK_FILE_SUCCESSS,
			error: DOWNLOAD_TASK_FILE_ERROR
		},
		"GET",
		"",
		"FETCH_DATA",
		"customer"
	)
}
