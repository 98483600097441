import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { SidebarLawyer } from "../../components/lawyer"

import {
	EditProfile,
	Task,
	ChangePassword,
	HistoryLawyer,
	TaskDetail,
	HistoryDetailLawyer
} from "../../views"

const PrivateRouterLawyer = ({
	component: Component,
	isAuthLawyer,
	...rest
}) => {
	if (isAuthLawyer) {
		return (
			<div>
				<div className='lawyer' >
					<SidebarLawyer />
					<div className='lawyer-wrapper'>
						<div className='main'>
							<div className='content'>
								<Switch>
									<Route
										exact
										path='/lawyer/'
										component={EditProfile}
									/>
									<Route
										exact
										path='/lawyer/editprofile'
										component={EditProfile}
									/>

									<Route
										exact
										path='/lawyer/resetpassword'
										component={ChangePassword}
									/>
									<Route
										exact
										path='/lawyer/task'
										component={Task}
									/>
									<Route
										exact
										path='/lawyer/history'
										component={HistoryLawyer}
									/>
									<Route
										exact
										path='/lawyer/historydetail'
										component={HistoryDetailLawyer}
									/>
									<Route
										exact
										path='/lawyer/taskdetail'
										component={TaskDetail}
									/>
									<Route
										exact
										path='/lawyer/task'
										component={Task}
									/>
									<Route
										render={() => (
											<div className='lawyer-404'>
												<span>
													404
													<small>
														Page not found.
													</small>
												</span>
											</div>
										)}
									/>
									{/* ทำหน้า 404 */}
								</Switch>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	} else {
		return <Redirect to='/lawyer/login' />
	}
}

const mapStateToProps = ({ AuthLawyer }) => ({ ...AuthLawyer })

export default connect(mapStateToProps)(PrivateRouterLawyer)
