import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { bindActionCreators } from "redux"
import { Switch, Route } from "react-router-dom";
import {
  Navbar,
  Footer,
  NavbarWithOutLogin
} from "./../../components/customer/";
import {
  Home,
  Login,
  Register,
  About,
  Contact,
  ForgetPassword,
  ResetPassword,
  ServiceDetail,
  ServiceDetailCustom,
  Profile,
  Payment,
  OrderHistory,
  OrderHistoryDetail,
  Thanks,
  ChangePasswordCus,
  TermAndCondition,
  PrivacyPolicy,
  Faq,
  DataProtectionPolicy,
  RefundPolicy,
  Maintenance
} from "../../views";

class index extends Component {
  constructor(props) {
    super(props);
    if (this.props.isCustomer) {
      //css for customer
      import("../../css/customer/styles.css");
    }
  }
  googleAnaly = () => {
		if (process.env.NODE_ENV === "production") {
			import("../../google.js")
		}
  }
  
  Maintenance = () => {
    return (
      <div>
        { this.googleAnaly() }
        {this.props.isAuth ? <Navbar /> : <NavbarWithOutLogin />}
        <Switch>
          <Route exact path="/" component={Maintenance} />
          <Route exact path="/home" component={Maintenance} /> 
          <Route exact path="/main" component={Maintenance} />
          <Route exact path="/property" component={Home} />
          <Route exact path="/property/page/:page" component={Home} />
          <Route exact path="/property/login" component={Login} />
          <Route exact path="/property/register" component={Register} />
          <Route exact path="/property/about" component={About} />
          <Route exact path="/property/contact" component={Contact} />
          <Route exact path="/property/servicedetail/:id" component={ServiceDetail} />
          <Route exact path="/property/servicedetailcustom/:id" component={ServiceDetailCustom} />
          <Route exact path="/property/forgetpassword" component={ForgetPassword} />
          <Route exact path="/property/resetpassword" component={ResetPassword} />
          <Route exact path="/property/changepassword" component={ChangePasswordCus} />
          <Route exact path="/property/profile" component={Profile} />
          <Route exact path="/property/payment" component={Payment} />
          <Route exact path="/property/orderhistory" component={OrderHistory} />
          <Route
            exact
            path="/property/orderhistorydetail/:id"
            component={OrderHistoryDetail}
          />
          <Route exact path="/property/termandcondition" component={TermAndCondition} />
          <Route exact path="/property/privacypolicy" component={PrivacyPolicy} />
          <Route exact path="/property/faq" component={Faq} />
          <Route
            exact
            path="/property/dataprotectionpolicy"
            component={DataProtectionPolicy}
          />
          <Route exact path="/property/refundpolicy" component={RefundPolicy} />
          <Route exact path="/property/thanks" component={Thanks} />
          <Route
            render={() => (
              <section className="main-content d-flex justify-content-center align-items-center">
                <div className="text-center">
                  <h1 className="d-block display-1 text-lawyer mb-0">404</h1>
                  <h3 className="text-uppercase mb-4">Page not found.</h3>
                  <Link to="/property" className="text-dark">
                    Back to home page.
                  </Link>
                </div>
              </section>
            )}
          />
        </Switch>
        <Footer />
      </div>
    );
  };
  render() {
    return (
      <Switch>
        {this.Maintenance()}
      </Switch>
    );
  }
}
const mapStateToProps = ({ AuthCustomer }) => ({ ...AuthCustomer });

export default connect(mapStateToProps)(index);
