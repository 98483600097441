import api from "../../service/api"

export const FETCH_CUSTOMERS_SUCCESS_ADMIN = "FETCH_CUSTOMERS_SUCCESS_ADMIN"
export const FETCH_CUSTOMERS_ERROR_ADMIN = "FETCH_CUSTOMERS_ERROR_ADMIN"

export const FETCH_CUSTOMER_SUCCESS_ADMIN = "FETCH_CUSTOMER_SUCCESS_ADMIN"
export const FETCH_CUSTOMER_ERROR_ADMIN = "FETCH_CUSTOMER_ERROR_ADMIN"

export const UPDATE_CUSTOMER_SUCCESS_ADMIN = "UPDATE_CUSTOMER_SUCCESS_ADMIN"
export const UPDATE_CUSTOMER_ERROR_ADMIN = "UPDATE_CUSTOMER_ERROR_ADMIN"

export const HANDLE_INPUT_CUSTOMER_ADMIN = "HANDLE_INPUT_CUSTOMER_ADMIN"
export const HANDLE_INPUT_CUSTOMER_TO_CUSTOMER_ADMIN =
	"HANDLE_INPUT_CUSTOMER_TO_CUSTOMER_ADMIN"

export const CLEAR_CUSTOMERS_IN_STORE_ADMIN = "CLEAR_CUSTOMERS_IN_STORE_ADMIN"
export const CLEAR_CUSTOMER_IN_STORE_ADMIN = "CLEAR_CUSTOMER_IN_STORE_ADMIN"

export const FETCH_PROVINCE_CUSTOMER_SUCCESS_ADMIN =
	"FETCH_PROVINCE_CUSTOMER_SUCCESS_ADMIN"
export const FETCH_PROVINCE_CUSTOMER_ERROR_ADMIN =
	"FETCH_PROVINCE_CUSTOMER_ERROR_ADMIN"

export const FETCH_PROVINCE_FOR_CUSTOMERS_SUCCESS_ADMIN =
	"FETCH_PROVINCE_FOR_CUSTOMERS_SUCCESS_ADMIN"
export const FETCH_PROVINCE_FOR_CUSTOMERS_ERROR_ADMIN =
	"FETCH_PROVINCE_FOR_CUSTOMERS_ERROR_ADMIN"

export const MAP_PROVINCES_TO_CUSTOMERS_ADMIN =
	"MAP_PROVINCES_TO_CUSTOMERS_ADMIN"

export const FETCH_COUNTRY_CUSTOMER_SUCCESS_ADMIN =
	"FETCH_COUNTRY_CUSTOMER_SUCCESS_ADMIN"
export const FETCH_COUNTRY_CUSTOMER_ERROR_ADMIN =
	"FETCH_COUNTRY_CUSTOMER_ERROR_ADMIN"

export const CHANGE_STATUS_CUSTOMER_SUCCESS_ADMIN =
	"CHANGE_STATUS_CUSTOMER_SUCCESS_ADMIN"
export const CHANGE_STATUS_CUSTOMER_ERROR_ADMIN =
	"CHANGE_STATUS_CUSTOMER_ERROR_ADMIN"

export const CHANGE_PASSWORD_SUCCESS_CUSTOMER_ADMIN =
	"CHANGE_PASSWORD_SUCCESS_CUSTOMER_ADMIN"
export const CHANGE_PASSWORD_ERROR_CUSTOMER_ADMIN =
	"CHANGE_PASSWORD_ERROR_CUSTOMER_ADMIN"

export const CLEAR_VALUE_IN_STORE_CUSTOMER_ADMIN =
	"CLEAR_VALUE_IN_STORE_CUSTOMER_ADMIN"

export const FETCH_CUSTOMER_ORDER_HISTORY_SUCCESS_ADMIN =
	"FETCH_CUSTOMER_ORDER_HISTORY_SUCCESS_ADMIN"
export const FETCH_CUSTOMER_ORDER_HISTORY_ERROR_ADMIN =
	"FETCH_CUSTOMER_ORDER_HISTORY_ERROR_ADMIN"

export const initialstate = {
	customers: [],
	customer: {
		id: "",
		uc_email: "",
		uc_member_code: "",
		uc_firstname: "",
		uc_lastname: "",
		uc_tel: "",
		uc_mobilecodeId: "+66", // ?
		uc_province: "",
		uc_countrycode: "",
		uc_status: true,
		provinceId: "",
		countryId: "",
		updatedBy: null,
		createdAt: "",
		updatedAt: "",
		deletedAt: null
	},
	orders: [],
	provinces: {},
	isLoading: true,
	isUpdated: false,
	isStatusChanged: false,
	isPasswordChanged: false
}

export default (state = initialstate, action) => {
	switch (action.type) {
		case FETCH_CUSTOMERS_SUCCESS_ADMIN:
			return {
				...state,
				customers: [...action.payload.data],
				isLoading: false
			}
		case FETCH_CUSTOMERS_ERROR_ADMIN:
			return {
				...state,
				isLoading: false
			}
		case FETCH_CUSTOMER_SUCCESS_ADMIN:
			return {
				...state,
				customer: {
					...state.customer,
					...action.payload.data
				}
			}
		case FETCH_CUSTOMER_ERROR_ADMIN:
			return {
				...state
			}
		case HANDLE_INPUT_CUSTOMER_ADMIN:
			return {
				...state,
				[action.payload.name]: action.payload.value
			}
		case HANDLE_INPUT_CUSTOMER_TO_CUSTOMER_ADMIN:
			return {
				...state,
				customer: {
					...state.customer,
					[action.payload.name]: action.payload.value
				}
			}
		case CLEAR_CUSTOMERS_IN_STORE_ADMIN:
			return {
				...state,
				customers: []
			}
		case CLEAR_CUSTOMER_IN_STORE_ADMIN:
			return {
				...state,
				customer: { ...initialstate.customer }
			}
		case UPDATE_CUSTOMER_SUCCESS_ADMIN:
			return {
				...state,
				isUpdated: true
			}
		case UPDATE_CUSTOMER_ERROR_ADMIN:
			return {
				...state,
				isUpdated: false
			}
		case FETCH_PROVINCE_CUSTOMER_SUCCESS_ADMIN:
			return {
				...state,
				customer: {
					...state.customer,
					uc_province: action.payload.data.pv_name
				}
			}
		case FETCH_PROVINCE_FOR_CUSTOMERS_SUCCESS_ADMIN:
			// let newCustomer = state.customers.map(item => {
			// 	let pv_name = ""
			// 	action.payload.data.map(country => {
			// 		if (country.id === item.provinceId) {
			// 			pv_name = country.pv_name
			// 		}
			// 	})
			// 	return {
			// 		...item,
			// 		uc_province: pv_name
			// 	}
			// })
			let provinces = {}
			action.payload.data.forEach(item => {
				provinces = { ...provinces, [item.id]: item }
			})
			return {
				...state,
				provinces
			}
		case MAP_PROVINCES_TO_CUSTOMERS_ADMIN:
			let newCustomers = state.customers.map(item => {
				return {
					...item,
					uc_province:
						state.provinces[item.provinceId]["pv_name"] || ""
				}
			})
			return {
				...state,
				customers: newCustomers
			}
		case FETCH_COUNTRY_CUSTOMER_SUCCESS_ADMIN:
			return {
				...state,
				customer: {
					...state.customer,
					uc_countrycode: action.payload.data.ct_short_name
				}
			}
		case CHANGE_STATUS_CUSTOMER_SUCCESS_ADMIN:
			return {
				...state,
				customer: {
					...state.customer,
					uc_status: !state.customer.uc_status
				},
				isStatusChanged: true
			}
		case CHANGE_STATUS_CUSTOMER_ERROR_ADMIN:
			return {
				...state,
				isStatusChanged: false
			}
		case CHANGE_PASSWORD_SUCCESS_CUSTOMER_ADMIN:
			return {
				...state,
				isPasswordChanged: true
			}
		case CHANGE_PASSWORD_ERROR_CUSTOMER_ADMIN:
			return {
				...state,
				isPasswordChanged: false
			}
		case CLEAR_VALUE_IN_STORE_CUSTOMER_ADMIN:
			return {
				...initialstate
			}
		case FETCH_CUSTOMER_ORDER_HISTORY_SUCCESS_ADMIN:
			return {
				...state,
				orders: [...action.payload.data],
				isLoading: false
			}
		case FETCH_CUSTOMER_ORDER_HISTORY_ERROR_ADMIN:
			return {
				...state,
				isLoading: false
			}
		default:
			return state
	}
}

export const fetchCustomers = () => {
	return api(
		"/api/customer",
		{
			success: FETCH_CUSTOMERS_SUCCESS_ADMIN,
			error: FETCH_CUSTOMERS_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const fetchCustomer = id => {
	return api(
		`/api/customer/${id}`,
		{
			success: FETCH_CUSTOMER_SUCCESS_ADMIN,
			error: FETCH_CUSTOMER_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const updateCustomer = (id, data) => {
	return api(
		`/api/customer/${id}`,
		{
			success: UPDATE_CUSTOMER_SUCCESS_ADMIN,
			error: UPDATE_CUSTOMER_ERROR_ADMIN
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const handleInputCustomerAdmin = ({ target }) => {
	return {
		type: HANDLE_INPUT_CUSTOMER_ADMIN,
		payload: target
	}
}

export const handleInputCustomerToCustomerAdmin = ({ target }) => {
	return {
		type: HANDLE_INPUT_CUSTOMER_TO_CUSTOMER_ADMIN,
		payload: target
	}
}

export const clearCustomersInStore = () => {
	return {
		type: CLEAR_CUSTOMERS_IN_STORE_ADMIN
	}
}

export const clearCustomerInStore = () => {
	return {
		type: CLEAR_CUSTOMER_IN_STORE_ADMIN
	}
}

export const fetchProvinceById = id => {
	return api(
		`/public/province/${id}`,
		{
			success: FETCH_PROVINCE_CUSTOMER_SUCCESS_ADMIN,
			error: FETCH_PROVINCE_CUSTOMER_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const fetchCountryById = id => {
	return api(
		`/public/country/${id}`,
		{
			success: FETCH_COUNTRY_CUSTOMER_SUCCESS_ADMIN,
			error: FETCH_COUNTRY_CUSTOMER_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const fetchProvinceForCustomers = () => {
	return api(
		`/public/provinces`,
		{
			success: FETCH_PROVINCE_FOR_CUSTOMERS_SUCCESS_ADMIN,
			error: FETCH_PROVINCE_FOR_CUSTOMERS_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const mapProvincesToCustomers = () => {
	return {
		type: MAP_PROVINCES_TO_CUSTOMERS_ADMIN
	}
}

export const changeStatusCustomer = (id, data) => {
	return api(
		`/api/customer/status/${id}`,
		{
			success: CHANGE_STATUS_CUSTOMER_SUCCESS_ADMIN,
			error: CHANGE_STATUS_CUSTOMER_ERROR_ADMIN
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const changePasswordCustomer = (id, data) => {
	return api(
		`/api/customer/password/${id}`,
		{
			success: CHANGE_PASSWORD_SUCCESS_CUSTOMER_ADMIN,
			error: CHANGE_PASSWORD_ERROR_CUSTOMER_ADMIN
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const clearValueInStore = () => {
	return {
		type: CLEAR_VALUE_IN_STORE_CUSTOMER_ADMIN
	}
}

export const fetchOrderHistory = id => {
	return api(
		`/api/order/customer/${id}`,
		{
			success: FETCH_CUSTOMER_ORDER_HISTORY_SUCCESS_ADMIN,
			error: FETCH_CUSTOMER_ORDER_HISTORY_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}
