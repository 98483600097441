import api from "../../service/api";

export const PACKAGES_DETAIL_SUCCESS = "PACKAGES_DETAIL_SUCCESS";
export const PACKAGES_DETAIL_ERROR = "PACKAGES_DETAIL_ERROR";

export const FETCH_PACKAGES_SUCCESS = "FETCH_PACKAGES_SUCCESS";
export const FETCH_PACKAGES_ERROR = "FETCH_PACKAGES_ERROR";

export const ORDER_PACKAGE_SUCCESS = "ORDER_PACKAGE_SUCCESS";
export const ORDER_PACKAGE_ERROR = "ORDER_PACKAGE_ERROR";

export const initialstate = {
    packages: [],
    packageDetail: null,
    orderPackage: null
}

const fetchPackagesAction = {
    success: FETCH_PACKAGES_SUCCESS,
    error: FETCH_PACKAGES_ERROR
}

const packageDetailAction = {
    success: PACKAGES_DETAIL_SUCCESS,
    error: PACKAGES_DETAIL_ERROR
}
const orderPackageAction = {
    success: ORDER_PACKAGE_SUCCESS,
    error: ORDER_PACKAGE_ERROR,
}
export default function (state = initialstate, action) {
    switch (action.type) {
        case FETCH_PACKAGES_SUCCESS: {
            return {
                ...state,
                packages: action.payload.data
            }
        }
        case PACKAGES_DETAIL_SUCCESS: {
            return {
                ...state,
                packageDetail: action.payload.data
            }
        }
        case ORDER_PACKAGE_SUCCESS: {
            return {
                ...state,
                orderPackage: action.payload.data
            }
        }
        default: return state
    }
}

export const fetchPackages = () => {
    return api(
        "/public/packages",
        fetchPackagesAction,
        "GET"
    )
}
export const packagedetail = (id) =>{
    return api(
        "/public/package/"+id,
        packageDetailAction,
        "GET"
    )
}
export const createOrder = (data) =>{
    return api(
        "/api/order",
        orderPackageAction,
        "POST",
        data
    )
}