import axios from "axios"
import token from "../service/token"
export default ({ dispatch }) => next => action => {
	if (action.type === "FETCH_DATA" && action.fetch) {
		const { method, url, body } = action.fetch
		let type = null
		if (action.tokentype === "") {
			if (url.indexOf(`/admin`) > -1) {
				type = "admin"
			} else if (url.indexOf(`/lawyer`) > -1) {
				type = "lawyer"
			} else {
				type = "customer"
			}
		} else {
			type = action.tokentype
		}

		const bearertoken = token.getToken(type)
		return axios
			.request({
				method,
				url,
				data: body,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${bearertoken}`
				}
			})
			.then(res => {
				// console.log(res)
				dispatch({
					type: action.actiontype.success,
					payload: res.data
				})
			})
			.catch(err => {
				if (err && err.response && err.response.status) {
					if (err.response.status === 401) {
						token.redirectToLogin(type)
					}
				}
				dispatch({
					type: action.actiontype.error,
					payload: err.response
				})
			})
	} else if (action.type === "UPLOAD_FILE" && action.fetch) {
		const { method, url, body } = action.fetch
		let type = null
		if (action.tokentype === "") {
			if (url.indexOf(`/admin`) > -1) {
				type = "admin"
			} else if (url.indexOf(`/lawyer`) > -1) {
				type = "lawyer"
			} else {
				type = "customer"
			}
		} else {
			type = action.tokentype
		}

		const bearertoken = token.getToken(type)

		return axios
			.request({
				method,
				url,
				data: body,
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${bearertoken}`
				}
			})
			.then(res => {
				dispatch({
					type: action.actiontype.success,
					payload: res.data.data
				})
			})
			.catch(err => {
				if (err.response) {
					dispatch({
						type: action.actiontype.error,
						payload: err.response
					})
				}
			})
	} else if (action.type && !action.fetch) {
		return next(action)
	}
}
