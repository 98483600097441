import token from "../../service/token"
import api from "../../service/api"
export const CHANGE_STORE_VALUE_LAWYER = "CHANGE_STORE_VALUE_LAWYER"

export const LOGIN_AUTH_SUCCESS_LAWYER = "LOGIN_AUTH_SUCCESS_LAWYER"
export const LOGIN_AUTH_ERROR_LAWYER = "LOGIN_AUTH_ERROR_LAWYER"

export const LOGOUT_AUTH_SUCCESS_LAWYER = "LOGOUT_AUTH_SUCCESS_LAWYER"
export const LOGOUT_AUTH_ERROR_LAWYER = "LOGOUT_AUTH_ERROR_LAWYER"

export const REGISTER_AUTH_SUCCESS_LAWYER = "REGISTER_AUTH_SUCCESS_LAWYER"
export const REGISTER_AUTH_ERROR_LAWYER = "REGISTER_AUTH_ERROR_LAWYER"

export const REGISTER_AUTH_SUCCESS_DUMMY = "REGISTER_AUTH_SUCCESS_DUMMY"
export const REGISTER_AUTH_ERROR_DUMMY = "REGISTER_AUTH_ERROR_DUMMY"

export const SEND_FORGET_PASS_LAWYER_SUCCESS = "SEND_FORGET_PASS_LAWYER_SUCCESS"
export const SEND_FORGET_PASS_LAWYER_ERROR = "SEND_FORGET_PASS_LAWYER_ERROR"

export const CHANGE_PASSWORD_LAWYER_SUCCESS = "CHANGE_PASSWORD_LAWYER_SUCCESS"
export const CHANGE_PASSWORD_LAWYER_ERROR = "CHANGE_PASSWORD_LAWYER_ERROR"

export const FETCH_PROFILE_LAWYER_SUCCESS = "FETCH_PROFILE_LAWYER_SUCCESS"
export const FETCH_PROFILE_LAWYER_ERROR = "FETCH_PROFILE_LAWYER_ERROR"

export const UPLOADPROFILE_SUCCESS = "UPLOADPROFILE_SUCCESS"
export const UPLOADPROFILE_ERROR = "UPLOADPROFILE_ERROR"

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
// const uploadprofileAction = {
// 	success: UPLOADPROFILE_SUCCESS,
// 	error: UPLOADPROFILE_ERROR
// }
export const initialstate = {
	name: "",
	isAuthLawyer: !!token.getToken("lawyer"),
	lawyer: {
		ly_email: "",
		ly_firstname: "",
		ly_last_login: "",
		ly_level: "",
		ly_member_code: "",
		ly_response_description: "",
		ly_status: false,
		ly_task_status: false,
		ly_tel: "",
		ly_verify: false,
		province: {
			id: "",
			pv_name: ""
		},
		country: {
			id: "",
			ct_short_name: ""
		}
	},
	isRegister: false,
	isForgetPasswordSuccess: false,
	isChangePasswordSuccess: false,
	msgErr: "Please try again.",
	isResetPassword: false
}
const resetPasswordAction = {
	success: RESET_PASSWORD_SUCCESS,
	error: RESET_PASSWORD_ERROR
};
export default function (state = initialstate, action) {

	switch (action.type) {
		case CHANGE_STORE_VALUE_LAWYER:
			return {
				...state,
				[action.payload.name]: action.payload.value
			}
		case LOGIN_AUTH_SUCCESS_LAWYER:
			if (typeof action.payload.data === 'string') {
				token.setToken(action.payload.data, "lawyer")
				localStorage.setItem("tabIndex", 0)
			}
			return {
				...state,
				isAuthLawyer: true,
				lawyer: token.decodeToken("lawyer")
				//[action.payload.name]: action.payload.value
			}
		case LOGIN_AUTH_ERROR_LAWYER:
			return {
				...state,
				isAuthLawyer: false,
				//lawyer: token.decodeToken("lawyer")
				//[action.payload.name]: action.payload.value
			}


		case LOGOUT_AUTH_SUCCESS_LAWYER:
			token.removeToken("lawyer")
			return {
				...initialstate,
				isAuthLawyer: false,
				isChangePasswordSuccess: state.isChangePasswordSuccess
			}
		case LOGOUT_AUTH_ERROR_LAWYER:
			token.removeToken("lawyer")
			return {
				...initialstate,
				isAuthLawyer: false,
				isChangePasswordSuccess: state.isChangePasswordSuccess
				//[action.payload.name]: action.payload.value
			}
		case REGISTER_AUTH_SUCCESS_LAWYER:

			return {
				...state,
				lawyer: {
					...state.lawyer,
					email: action.payload.data.email
				},
				isRegister: true,
				msgErr: "",
			}
		case REGISTER_AUTH_ERROR_LAWYER:
			return {
				...state,
				isRegister: false,
				msgErr:
					action
						&& action.payload
						&& action.payload.data
						&& action.payload.data.error[0]
						&& action.payload.data.error[0].message === "ly_email must be unique" ?
						"This email is already taken."
						: "Please try again."
			}
		case SEND_FORGET_PASS_LAWYER_SUCCESS:
			return {
				...state,
				isForgetPasswordSuccess: true
			}
		case SEND_FORGET_PASS_LAWYER_ERROR:
			return {
				...state,
				isForgetPasswordSuccess: false
			}
		case CHANGE_PASSWORD_LAWYER_SUCCESS:
			return {
				...state,
				isChangePasswordSuccess: true
			}
		case CHANGE_PASSWORD_LAWYER_ERROR:
			return {
				...state,
				isChangePasswordSuccess: false
			}
		case FETCH_PROFILE_LAWYER_SUCCESS:

			return {
				...state,
				lawyer: action.payload.data,

			}
		case FETCH_PROFILE_LAWYER_ERROR:
			token.removeToken("lawyer")
			return {
				...state,

			}
		case UPLOADPROFILE_SUCCESS:
			return {
				...state,
				lawyer: action.payload.data
			}
		case UPLOADPROFILE_ERROR:
			return {
				...state
			}
		case RESET_PASSWORD_SUCCESS:
			return {
				...state,
				isResetPassword: true
			};
		case RESET_PASSWORD_ERROR:
			return {
				...state,
				isResetPassword: false
			};
		default:
			return state
	}
}

export const handleInputLawyer = ({ target }) => {
	return {
		type: CHANGE_STORE_VALUE_LAWYER,
		payload: {
			name: target.name,
			value: target.value
		}
	}
}
export const loginlawyer = data => {
	return api(
		"/auth/signin/lawyer",
		{
			success: LOGIN_AUTH_SUCCESS_LAWYER,
			error: LOGIN_AUTH_ERROR_LAWYER
		},
		"POST",
		data
	)
}
export const logoutlawyer = () => {
	return { type: LOGOUT_AUTH_SUCCESS_LAWYER }
	// return api(
	// 	"/",
	// 	{
	// 		success: LOGOUT_AUTH_SUCCESS_LAWYER,
	// 		error: LOGOUT_AUTH_ERROR_LAWYER
	// 	},
	// 	"POST"
	// )
}
export const register = data => {

	return api(
		"/auth/signup/lawyer",
		{
			success: REGISTER_AUTH_SUCCESS_LAWYER,
			error: REGISTER_AUTH_ERROR_LAWYER
		},
		"POST",
		data
	)
}
export const sendForgetPasswordLawyer = data => {
	return api(
		"/auth/forgetpassword/lawyer",
		{
			success: SEND_FORGET_PASS_LAWYER_SUCCESS,
			error: SEND_FORGET_PASS_LAWYER_ERROR
		},
		"POST",
		data
	)
}
export const changePasswordLawyer = data => {
	return api(
		"/auth/changepassword/lawyer",
		{
			success: CHANGE_PASSWORD_LAWYER_SUCCESS,
			error: CHANGE_PASSWORD_LAWYER_ERROR
		},
		"PUT",
		data
	)
}
export const fetchProfile = () => {
	return api(
		"/api/lawyer/profile",
		{
			success: FETCH_PROFILE_LAWYER_SUCCESS,
			error: FETCH_PROFILE_LAWYER_ERROR
		},
		"GET"
	)
}

export const resetPassword = (data) => {
	return api("/auth/resetpassword", resetPasswordAction, "PUT", data);
};

// export const uploadprofile = data => {
// 	return api("/api/lawyer/" + data.id, uploadprofileAction, "PUT", data)
// }
