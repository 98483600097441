// คำขึ้นต้น LAWYER_PROFILE_

import token from "../../service/token"
import api from "../../service/api"

export const LAWYER_PROFILE_UPLOADPROFILE_SUCCESS =
	"LAWYER_PROFILE_LAWYER_PROFILE_UPLOADPROFILE_SUCCESS"
export const LAWYER_PROFILE_UPLOADPROFILE_ERROR =
	"LAWYER_PROFILE_UPLOADPROFILE_ERROR"

export const LAWYER_GET_BOOKBANK_IMAGE_SUCCESS =
	"LAWYER_GET_BOOKBANK_IMAGE_SUCCESS"
export const LAWYER_GET_BOOKBANK_IMAGE_ERROR = "LAWYER_GET_BOOKBANK_IMAGE_ERROR"

export const LAWYER_PROFILE_GET_URL_UPLOAD_SUCCESS =
	"LAWYER_PROFILE_GET_URL_UPLOAD_SUCCESS"
export const LAWYER_PROFILE_GET_URL_UPLOAD_ERROR =
	"LAWYER_PROFILE_GET_URL_UPLOAD_ERROR"

export const LAWYER_PROFILE_GET_URL_UPLOAD_FOR_BANK_SUCCESS =
	"LAWYER_PROFILE_GET_URL_UPLOAD_FOR_BANK_SUCCESS"
export const LAWYER_PROFILE_GET_URL_UPLOAD_FOR_BABK_ERROR =
	"LAWYER_PROFILE_GET_URL_UPLOAD_FOR_BABK_ERROR"

export const LAWYER_PROFILE_GET_URL_UPLOAD_FOR_LICENSE_SUCCESS =
	"LAWYER_PROFILE_GET_URL_UPLOAD_FOR_LICENSE_SUCCESS"
export const LAWYER_PROFILE_GET_URL_UPLOAD_FOR_LICENSE_ERROR =
	"LAWYER_PROFILE_GET_URL_UPLOAD_FOR_LICENSE_ERROR"

export const LAWYER_PROFILE_GET_URL_UPLOAD_FOR_TRANSCRIPT_SUCCESS =
	"LAWYER_PROFILE_GET_URL_UPLOAD_FOR_TRANSCRIPT_SUCCESS"
export const LAWYER_PROFILE_GET_URL_UPLOAD_FOR_TRANSCRIPT_ERROR =
	"LAWYER_PROFILE_GET_URL_UPLOAD_FOR_TRANSCRIPT_ERROR"

// export const LAWYER_PROFILE_UPLOAD_TASK_PROFILE_SUCCESS =
//     "LAWYER_PROFILE_UPLOAD_TASK_PROFILE_SUCCESS";
// export const LAWYER_PROFILE_UPLOAD_TASK_PPROFILE_ERROR =
//     "LAWYER_PROFILE_UPLOAD_TASK_PPROFILE_ERROR";

export const INACTIVE_SUCCESS = "INACTIVE_SUCCESS"
export const INACTIVE_ERROR = "INACTIVE_ERROR"

export const ACTIVE_SUCCESS = "ACTIVE_SUCCESS"
export const ACTIVE_ERROR = "ACTIVE_ERROR"

export const UPLOAD_BANK_ACCOUNT_SUCCESS = "UPLOAD_BANK_ACCOUNT_SUCCESS"
export const UPLOAD_BANK_ACCOUNT_ERROR = "UPLOAD_BANK_ACCOUNT_ERROR"

export const UPLOAD_LICENSE_SUCCESS = "UPLOAD_LICENSE_SUCCESS"
export const UPLOAD_LICENSE_ERROR = "UPLOAD_LICENSE_ERROR"

export const LAWYER_TASK_STATUS_SUCCESS = "LAWYER_TASK_STATUS_SUCCESS"
export const LAWYER_TASK_STATUS_ERROR = "LAWYER_TASK_STATUS_ERROR"

export const CLEAR_PROFILE = "CLEAR_PROFILE"

export const initialstate = {
	name: "",
	isRegister: false,
	Profilelawyer: {},
	isAuthLawyer: !!token.getToken("lawyer"),
	isChangePassword: !!token.getToken("lawyer"),
	bankUrl: "",
	licenseUrl: "",
	degreeUrl: "",
	bankKey: "",
	licenseKey: "",
	degreeKey: "",
	bankSourceImage: "",
	isInterestedChanged: false,
	isNotiChanged: false,
}

const uploadprofileAction = {
	success: LAWYER_PROFILE_UPLOADPROFILE_SUCCESS,
	error: LAWYER_PROFILE_UPLOADPROFILE_ERROR
}
// const updatetaskprofileAction = {
//     success: LAWYER_PROFILE_UPLOAD_TASK_PROFILE_SUCCESS,
//     error: LAWYER_PROFILE_UPLOAD_TASK_PPROFILE_ERROR
// };
const updateTaskStatusAction = {
	success: LAWYER_TASK_STATUS_SUCCESS,
	error: LAWYER_TASK_STATUS_ERROR
}
const uploadbankaccountAction = {
	success: UPLOAD_BANK_ACCOUNT_SUCCESS,
	error: UPLOAD_BANK_ACCOUNT_ERROR
}
const uploadlicenseAction = {
	success: UPLOAD_LICENSE_SUCCESS,
	error: UPLOAD_LICENSE_ERROR
}
export default function(state = initialstate, action) {
	switch (action.type) {
		// case UPLOADPROFILE_SUCCESS:

		//     return {
		//         ...state,
		//         isAuth: false,
		//     }
		// case LAWYER_PROFILE_UPLOADPROFILE_ERROR:

		//     return {
		//         ...state,
		//         isAuth: false,
		//     }
		// case LAWYER_PROFILE_UPLOAD_TASK_PROFILE_SUCCESS:
		// 	return {
		// 		...state,
		// 		isAuthLawyer: false,
		// 		lawyer: action.payload.data,
		// 	}
		case LAWYER_PROFILE_UPLOADPROFILE_SUCCESS:
			return {
				...state
			}
		case LAWYER_PROFILE_GET_URL_UPLOAD_FOR_BANK_SUCCESS:
			return {
				...state,
				bankUrl: action.payload.data,
				bankKey: action.payload.key
			}
		case LAWYER_PROFILE_GET_URL_UPLOAD_FOR_LICENSE_SUCCESS:
			return {
				...state,
				licenseUrl: action.payload.data,
				licenseKey: action.payload.key
			}
		case LAWYER_PROFILE_GET_URL_UPLOAD_FOR_TRANSCRIPT_SUCCESS:
			return {
				...state,
				degreeUrl: action.payload.data,
				degreeKey: action.payload.key
			}
		case LAWYER_GET_BOOKBANK_IMAGE_SUCCESS:
			return {
				...state,
				bankSourceImage: action.payload.data
			}
		case LAWYER_TASK_STATUS_SUCCESS:
			return {
				...state,
				isNotiChanged: true
			}
		case LAWYER_TASK_STATUS_ERROR:
			return {
				...state,
				isNotiChanged: false
			}
		case UPLOAD_BANK_ACCOUNT_SUCCESS:
			return {
				...state
			}
		case UPLOAD_BANK_ACCOUNT_ERROR:
			return {
				...state
			}
		case ACTIVE_SUCCESS:
			return {
				...state,
				isInterestedChanged: true
			}
		case ACTIVE_ERROR:
			return {
				...state,
				isInterestedChanged: false
			}
		case INACTIVE_SUCCESS:
			return {
				...state,
				isInterestedChanged: true
			}
		case INACTIVE_ERROR:
			return {
				...state,
				isInterestedChanged: false
			}
		case UPLOAD_LICENSE_SUCCESS:
			localStorage.setItem("isUpdatedLicense",true)
			return {
				...state
			}
		case UPLOAD_LICENSE_ERROR:
			localStorage.setItem("isUpdatedLicense",false)
			return {
				...state
			}
		case CLEAR_PROFILE:
			return {
				...state,
				...initialstate
			}

		default:
			return state
	}
}

export const uploadprofile = data => {
	return api("/api/lawyer/" + data.id, uploadprofileAction, "PUT", data)
}

export const uploadbankaccount = data => {
	return api("/api/lawyer/bank", uploadbankaccountAction, "PUT", data)
}

export const uploadlicense = data => {
	return api("/api/lawyer/license", uploadlicenseAction, "PUT", data)
}

export const uploadpicture = data => {
	return api(data.url, uploadlicenseAction, "PUT", data)
}

export const updatetask = data => {
	if (data.status) {
		return api(
			"/api/lawyer/activeservice/" + data.id,
			{
				success: ACTIVE_SUCCESS,
				error: ACTIVE_ERROR
			},
			"PUT"
		)
	} else {
		return api(
			"/api/lawyer/inactiveservice/" + data.id,
			{
				success: INACTIVE_SUCCESS,
				error: INACTIVE_ERROR
			},
			"PUT"
		)
	}
}
export const updateTaskStatus = data => {
	return api("/api/lawyer/task", updateTaskStatusAction, "PUT", data)
}

export const getUrlFileImage = data => {
	return api(
		`/api/upload/${data}`,
		{
			success: LAWYER_GET_BOOKBANK_IMAGE_SUCCESS,
			error: LAWYER_GET_BOOKBANK_IMAGE_ERROR
		},
		"GET"
	)
}

export const getUrlForUploadFileLawyer = data => {
	if (data.name === "bank") {
		return api(
			"/api/upload/lawyer",
			{
				success: LAWYER_PROFILE_GET_URL_UPLOAD_FOR_BANK_SUCCESS,
				error: LAWYER_PROFILE_GET_URL_UPLOAD_FOR_BABK_ERROR
			},
			"POST",
			data
		)
	} else if (data.name === "license") {
		return api(
			"/api/upload/lawyer",
			{
				success: LAWYER_PROFILE_GET_URL_UPLOAD_FOR_LICENSE_SUCCESS,
				error: LAWYER_PROFILE_GET_URL_UPLOAD_FOR_LICENSE_ERROR
			},
			"POST",
			data
		)
	} else {
		return api(
			"/api/upload/lawyer",
			{
				success: LAWYER_PROFILE_GET_URL_UPLOAD_FOR_TRANSCRIPT_SUCCESS,
				error: LAWYER_PROFILE_GET_URL_UPLOAD_FOR_TRANSCRIPT_ERROR
			},
			"POST",
			data
		)
	}
}

export const clearProfileLawyer = () => {
	return { type: CLEAR_PROFILE }
}
