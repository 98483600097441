import token from "../../service/token";
import api from "../../service/api";
export const CHANGE_STORE_VALUE_CUSTOMER = "CHANGE_STORE_VALUE_CUSTOMER";

export const LOGIN_AUTH_SUCCESS = "LOGIN_AUTH_SUCCESS";
export const LOGIN_AUTH_ERROR = "LOGIN_AUTH_ERROR";

export const LOGOUT_AUTH_SUCCESS = "LOGOUT_AUTH_SUCCESS";

export const REGISTER_AUTH_SUCCESS = "REGISTER_AUTH_SUCCESS";

export const REGISTER_AUTH_ERROR = "REGISTER_AUTH_ERROR";

export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_ERROR = "VERIFY_ERROR";

export const CHANGEPASSWORD_SUCCESS = "CHANGEPASSWORD_SUCCESS";
export const CHANGEPASSWORD_ERROR = "CHANGEPASSWORD_ERROR";

export const FORGETPASSWORD_SUCCESS = "FORGETPASSWORD_SUCCESS";
export const FORGETPASSWORD_ERROR = "FORGETPASSWORD_ERROR";

export const FETCH_PORFILE_CUSTOMER_SUCCESS = "FETCH_PORFILE_CUSTOMER_SUCCESS";
export const FETCH_PORFILE_CUSTOMER_ERROR = "FETCH_PORFILE_CUSTOMER_ERROR";

export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";
export const SEND_MAIL_ERROR = "SEND_MAIL_ERROR";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const CHECK_PAYMENT_SUCCESS = "CHECK_PAYMENT_SUCCESS";
export const CHECK_PAYMENT_ERROR = "CHECK_PAYMENT_ERROR";

export const initialstate = {
  name: "",
  isContact: false,
  isRegister: false,
  customer: token.decodeToken(),
  isAuth: !!token.getToken("customer"),
  isChangePassword: !!token.getToken("customer"),
  isForgetPasswordSuccess: false,
  isResetPassword: false,
  isPaymentSuccess: false,
};
const verifyAction = {
  success: VERIFY_SUCCESS,
  error: VERIFY_ERROR
};
const changepasswordAction = {
  success: CHANGEPASSWORD_SUCCESS,
  error: CHANGEPASSWORD_ERROR
};
const forgetpasswordAction = {
  success: FORGETPASSWORD_SUCCESS,
  error: FORGETPASSWORD_ERROR
};
const fetchprofileAction = {
  success: FETCH_PORFILE_CUSTOMER_SUCCESS,
  error: FETCH_PORFILE_CUSTOMER_ERROR
};
const sendmailAction = {
  success: SEND_MAIL_SUCCESS,
  error: SEND_MAIL_ERROR
};

const resetPasswordAction = {
  success: RESET_PASSWORD_SUCCESS,
  error: RESET_PASSWORD_ERROR
};

const checkPaymentAction = {
  success: CHECK_PAYMENT_SUCCESS,
  error: CHECK_PAYMENT_ERROR
}
export default function (state = initialstate, action) {
  switch (action.type) {
    case CHANGE_STORE_VALUE_CUSTOMER:
      return {
        ...state,
        [action.payload.name]: action.payload.value
      };
    case LOGIN_AUTH_SUCCESS:
      if (typeof action.payload.data === "string") {
        token.setToken(action.payload.data);
      }
      return {
        ...state,
        isAuth: true
      };
    case LOGIN_AUTH_ERROR:
      return {
        ...state,
        isAuth: false
      };
    case LOGOUT_AUTH_SUCCESS:
      //อย่าลืม set token
      token.removeToken("customer");
      return {
        ...state,
        isAuth: false
      };
    case REGISTER_AUTH_SUCCESS:
      return {
        ...state,
        isRegister: true,
        customer: action.payload
      };
    case REGISTER_AUTH_ERROR:
      return {
        ...state,
        isRegister: false
      };
    case VERIFY_SUCCESS:
      return {
        ...state
      };
    case VERIFY_ERROR:
      return {
        ...state
      };
    case CHANGEPASSWORD_SUCCESS:
      token.removeToken("customer");
      return {
        ...state,
        isChangePassword: true,
        isAuth: false
      };
    case CHANGEPASSWORD_ERROR:
      return {
        ...state,
        isChangePassword: false,
        isAuth: true
      };
    case FORGETPASSWORD_SUCCESS:
      return {
        ...state,
        isAuth: false,
        isForgetPasswordSuccess: true
      };
    case FORGETPASSWORD_ERROR:
      return {
        ...state,
        isAuth: false,
        isForgetPasswordSuccess: false
      };
    case FETCH_PORFILE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.payload.data
        }
      };
    case FETCH_PORFILE_CUSTOMER_ERROR:
      token.removeToken("customer");
      return {
        ...state,
        isAuth: false,
        customer: {}
      };
    case SEND_MAIL_SUCCESS:
      return {
        ...state,
        isContact: true
      };
    case SEND_MAIL_ERROR:
      return {
        ...state,
        isContact: false
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPassword: true
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        isResetPassword: false
      };
      case CHECK_PAYMENT_SUCCESS:
        return {
          ...state,
          isPaymentSuccess: action.payload && action.payload.success 
        };
      case CHECK_PAYMENT_ERROR:
        return {
          ...state,
          isPaymentSuccess: false
        };
    default:
      return state;
  }
}

export const handleInputCustomer = ({ target }) => {
  return {
    type: CHANGE_STORE_VALUE_CUSTOMER,
    payload: {
      name: target.name,
      value: target.value
    }
  };
};
export const login = data => {
  return api(
    "/auth/signin/customer",
    {
      success: LOGIN_AUTH_SUCCESS,
      error: LOGIN_AUTH_ERROR
    },
    "POST",
    data
  );
};
export const logout = () => {
  return {
    type: LOGOUT_AUTH_SUCCESS
  };
};

export const register = data => {
  return api(
    "/auth/signup/customer",
    {
      success: REGISTER_AUTH_SUCCESS,
      error: REGISTER_AUTH_ERROR
    },
    "POST",
    data
  );
};
export const verify = data => {
  return api("/auth/verify/customer", verifyAction, "POST", data);
};
export const changepassword = data => {
  return api(
    "/auth/changepassword/customer",
    changepasswordAction,
    "PUT",
    data
  );
};
export const forgetpassword = data => {
  return api(
    "/auth/forgetpassword/customer",
    forgetpasswordAction,
    "POST",
    data
  );
};
export const sendmail = data => {
  return api("/public/contact", sendmailAction, "POST", data);
};

export const fetchProfile = () => {
  return api("/api/customer/profile", fetchprofileAction, "GET");
};

export const resetPassword = (data) => {
  return api("/auth/resetpassword", resetPasswordAction, "PUT", data);
};


export const checkPayment = (id) => {
  return api("/api/omise/checkcharge/"+id, checkPaymentAction, "GET");
};