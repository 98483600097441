import React, { Component } from 'react';
import { Provider } from "react-redux"
import { createStore, applyMiddleware, compose } from "redux"
import { BrowserRouter, Switch } from "react-router-dom"
import reduxThunk from "redux-thunk"

import reducers from "./reducers"
import Router from "./router"
import fetchAPI from "./middleware/fetchMiddleware"

class App extends Component {
  render() {
    let store = null

    if (process.env.NODE_ENV === "production") {
      const createStoreWithMiddleware = compose(
        applyMiddleware(reduxThunk, fetchAPI)
      )(createStore)
      store = createStoreWithMiddleware(reducers, {})
    } else {
      const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
      store = createStore(
        reducers,
        composeEnhancers(applyMiddleware(reduxThunk, fetchAPI))
      )
    }

    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Router />
          </Switch>
        </BrowserRouter>
      </Provider>

    );
  }
}
export default App;