import api from "../../service/api"

export const FETCH_LAWYERS_SUCCESS_ADMIN = "FETCH_LAWYERS_SUCCESS_ADMIN"
export const FETCH_LAWYERS_ERROR_ADMIN = "FETCH_LAWYERS_ERROR_ADMIN"

export const HANDLE_INPUT_LAWYER_ADMIN = "HANDLE_INPUT_LAWYER_ADMIN"

export const CLEAR_LAWYERS_IN_STORE_ADMIN = "CLEAR_LAWYERS_IN_STORE_ADMIN"

export const FETCH_PROVINCE_FOR_LAWYERS_SUCCESS_ADMIN =
	"FETCH_PROVINCE_FOR_LAWYERS_SUCCESS_ADMIN"
export const FETCH_PROVINCE_FOR_LAWYERS_ERROR_ADMIN =
	"FETCH_PROVINCE_FOR_LAWYERS_ERROR_ADMIN"

export const FETCH_LAWYER_SUCCESS_ADMIN = "FETCH_LAWYER_SUCCESS_ADMIN"
export const FETCH_LAWYER_ERROR_ADMIN = "FETCH_LAWYER_ERROR_ADMIN"

export const MAP_PROVINCES_TO_LAWYERS_ADMIN = "MAP_PROVINCES_TO_LAWYERS_ADMIN"

export const APPROVE_LAWYER_SUCCESS_ADMIN = "APPROVE_LAWYER_SUCCESS_ADMIN"
export const APPROVE_LAWYER_ERROR_ADMIN = "APPROVE_LAWYER_ERROR_ADMIN"

export const REJECT_LAWYER_SUCCESS_ADMIN = "REJECT_LAWYER_SUCCESS_ADMIN"
export const REJECT_LAWYER_ERROR_ADMIN = "REJECT_LAWYER_ERROR_ADMIN"

export const CLEAR_VALUE_IN_STORE_LAWYER_ADMIN =
	"CLEAR_VALUE_IN_STORE_LAWYER_ADMIN"

export const CHANGE_PASSWORD_LAWYER_SUCCESS_ADMIN =
	"CHANGE_PASSWORD_LAWYER_SUCCESS_ADMIN"
export const CHANGE_PASSWORD_LAWYER_ERROR_ADMIN =
	"CHANGE_PASSWORD_LAWYER_ERROR_ADMIN"

export const GET_URL_FILE_IMAGE_LAWYER_SUCCESS_ADMIN =
	"GET_URL_FILE_IMAGE_LAWYER_SUCCESS_ADMIN"
export const GET_URL_FILE_IMAGE_LAWYER_ERROR_ADMIN =
	"GET_URL_FILE_IMAGE_LAWYER_ERROR_ADMIN"

export const GET_URL_TRANSCRIPT_LAWYER_SUCCESS_ADMIN =
	"GET_URL_TRANSCRIPT_LAWYER_SUCCESS_ADMIN"
export const GET_URL_TRANSCRIPT_LAWYER_ERROR_ADMIN =
	"GET_URL_TRANSCRIPT_LAWYER_ERROR_ADMIN"

export const GET_URL_LICENSE_LAWYER_SUCCESS_ADMIN =
	"GET_URL_LICENSE_LAWYER_SUCCESS_ADMIN"
export const GET_URL_LICENSE_LAWYER_ERROR_ADMIN =
	"GET_URL_LICENSE_LAWYER_ERROR_ADMIN"

export const UPDATE_LAWYER_SUCCESS_ADMIN = "UPDATE_LAWYER_SUCCESS_ADMIN"
export const UPDATE_LAWYER_ERROR_ADMIN = "UPDATE_LAWYER_ERROR_ADMIN"

export const ACTIVE_SERVICE_SUCCESS_ADMIN = "ACTIVE_SERVICE_SUCCESS_ADMIN"
export const ACTIVE_SERVICE_ERROR_ADMIN = "ACTIVE_SERVICE_ERROR_ADMIN"

export const INACTIVE_SERVICE_SUCCESS_ADMIN = "INACTIVE_SERVICE_SUCCESS_ADMIN"
export const INACTIVE_SERVICE_ERROR_ADMIN = "INACTIVE_SERVICE_ERROR_ADMIN"

export const CHANGE_TASK_STATUS_LAWYER_SUCCESS_ADMIN =
	"CHANGE_TASK_STATUS_LAWYER_SUCCESS_ADMIN"
export const CHANGE_TASK_STATUS_LAWYER_ERROR_ADMIN =
	"CHANGE_TASK_STATUS_LAWYER_ERROR_ADMIN"

export const GET_URL_UPLOAD_LICENSE_SUCCESS_ADMIN =
	"GET_URL_UPLOAD_LICENSE_SUCCESS_ADMIN"
export const GET_URL_UPLOAD_LICENSE_ERROR_ADMIN =
	"GET_URL_UPLOAD_LICENSE_ERROR_ADMIN"

export const GET_URL_UPLOAD_TRANSCRIPT_SUCCESS_ADMIN =
	"GET_URL_UPLOAD_TRANSCRIPT_SUCCESS_ADMIN"
export const GET_URL_UPLOAD_TRANSCRIPT_ERROR_ADMIN =
	"GET_URL_UPLOAD_TRANSCRIPT_ERROR_ADMIN"

export const UPLOAD_LICENSE_SUCCESS_ADMIN = "UPLOAD_LICENSE_SUCCESS_ADMIN"
export const UPLOAD_LICENSE_ERROR_ADMIN = "UPLOAD_LICENSE_ERROR_ADMIN"

export const SET_VALUE_IN_STORE_LAWYER_ADMIN = "SET_VALUE_IN_STORE_LAWYER_ADMIN"

export const CHANGE_LAWYER_LEVEL_SUCCESS_ADMIN =
	"CHANGE_LAWYER_LEVEL_SUCCESS_ADMIN"
export const CHANGE_LAWYER_LEVEL_ERROR_ADMIN = "CHANGE_LAWYER_LEVEL_ERROR_ADMIN"

export const FETCH_ORDER_HISTORY_LAWYER_SUCCESS_ADMIN =
	"FETCH_ORDER_HISTORY_LAWYER_SUCCESS_ADMIN"
export const FETCH_ORDER_HISTORY_LAWYER_ERROR_ADMIN =
	"FETCH_ORDER_HISTORY_LAWYER_ERROR_ADMIN"

export const CHANGE_LAWYER_STATUS_SUCCESS_ADMIN =
	"CHANGE_LAWYER_STATUS_SUCCESS_ADMIN"
export const CHANGE_LAWYER_STATUS_ERROR_ADMIN =
	"CHANGE_LAWYER_STATUS_ERROR_ADMIN"

export const GET_URL_UPLOAD_BANK_SUCCESS_ADMIN =
	"GET_URL_UPLOAD_BANK_SUCCESS_ADMIN"
export const GET_URL_UPLOAD_BANK_ERROR_ADMIN = "GET_URL_UPLOAD_BANK_ERROR_ADMIN"

export const UPLOAD_BANK_SUCCESS_ADMIN = "UPLOAD_BANK_SUCCESS_ADMIN"
export const UPLOAD_BANK_ERROR_ADMIN = "UPLOAD_BANK_ERROR_ADMIN"

export const initialstate = {
	lawyers: [],
	lawyer: {
		ly_id: "",
		ly_firstname: "",
		ly_lastname: "",
		ly_email: "",
		ly_password: "",
		ly_tel: "",
		ly_status: "",
		province: "",
		countrycode: "",
		notification: false,
		report: true,
		contract: false,
		transfer: true,
		accountname: "",
		accountnumber: "",
		accountimage: "",
		license: "",
		degree: "",
		licendate: new Date(),
		ly_verify: true,
		ly_response_description: "",

		ly_bank_name: null,
		ly_bank_account_name: null,
		ly_bank_number: null,
		ly_picture_bank_url: null,
		ly_picture_transcript_url: null,
		ly_picture_license_url: null,
		ly_date_license_allow: new Date(),
		ly_level: "",
		services: [],
		ly_task_status: false,
		ly_member_code: "",
		ly_countrycode: "",
		ly_service_report: false,
		ly_service_contract: false,
		ly_service_transfer: false,
		ly_service_review_report: false,
		ly_service_review_contract: false,
		ly_picture_bank_url_in_cookie: null,
		ly_picture_transcript_url_in_cookie: null,
		ly_picture_license_url_in_cookie: null,

		country: {
			ct_short_name: "",
			id: ""
		}
	},
	orders: [],
	provinces: {},
	isLoading: true,
	isLawyerApproved: false,
	isLawyerRejected: false,
	isPasswordChanged: false,
	isLawyerUpdated: false,
	isActiveSuccess: false,
	isInactiveSuccess: false,
	isTaskStatusChanged: false,
	isLicenseUploaded: false,
	isBankUploaded: false,
	isLevelChanged: false,
	isLawyerStatusChanged: false,

	urlForUploadLicense: null,
	keyForUploadLicense: null,

	urlForUploadTranscript: null,
	keyForUploadTranscript: null,

	urlForUploadBank: null,
	keyForUploadBank: null
}

export default (state = initialstate, action) => {
	switch (action.type) {
		case FETCH_LAWYERS_SUCCESS_ADMIN:
			return {
				...state,
				lawyers: [...action.payload.data],
				isLoading: false
			}
		case FETCH_LAWYERS_ERROR_ADMIN:
			return {
				...state,
				isLoading: false
			}
		case FETCH_LAWYER_SUCCESS_ADMIN:
			let ly_service_report = false
			let ly_service_contract = false
			let ly_service_transfer = false
			let ly_service_review_report = false
			let ly_service_review_contract = false
			action.payload.data.services.forEach(service => {
				if (
					service.sv_name === "Report" &&
					service.lawyer_service &&
					service.lawyer_service.deletedAt === null
				) {
					ly_service_report = true
				} else if (
					service.sv_name === "Contract" &&
					service.lawyer_service &&
					service.lawyer_service.deletedAt === null
				) {
					ly_service_contract = true
				} else if (
					service.sv_name === "Transfer" &&
					service.lawyer_service &&
					service.lawyer_service.deletedAt === null
				) {
					ly_service_transfer = true
				} else if (
					service.sv_name === "Review report" &&
					service.lawyer_service &&
					service.lawyer_service.deletedAt === null
				) {
					ly_service_review_report = true
				} else if (
					service.sv_name === "Review contract" &&
					service.lawyer_service &&
					service.lawyer_service.deletedAt === null
				) {
					ly_service_review_contract = true
				}
			})
			return {
				...state,
				lawyer: {
					...initialstate.lawyer,
					...action.payload.data,
					ly_service_report: ly_service_report,
					ly_service_contract: ly_service_contract,
					ly_service_transfer: ly_service_transfer,
					ly_service_review_report: ly_service_review_report,
					ly_service_review_contract: ly_service_review_contract
				},
				keyForUploadTranscript:
					action.payload.data.ly_picture_license_url,
				keyForUploadLicense:
					action.payload.data.ly_picture_transcript_url
			}
		case FETCH_LAWYER_ERROR_ADMIN:
			return {
				...state
			}
		case HANDLE_INPUT_LAWYER_ADMIN:
			return {
				...state,
				lawyer: {
					...state.lawyer,
					[action.payload.name]: action.payload.value
				}
			}
		case CLEAR_LAWYERS_IN_STORE_ADMIN:
			return {
				...state,
				lawyers: []
			}
		case FETCH_PROVINCE_FOR_LAWYERS_SUCCESS_ADMIN:
			let provinces = {}
			action.payload.data.forEach(item => {
				provinces = { ...provinces, [item.id]: item }
			})
			return {
				...state,
				provinces
			}
		case MAP_PROVINCES_TO_LAWYERS_ADMIN:
			let newLawyers = state.lawyers.map(item => {
				return {
					...item,
					ly_province:
						state.provinces[item.provinceId]["pv_name"] || ""
				}
			})
			return {
				...state,
				lawyers: newLawyers
			}
		case APPROVE_LAWYER_SUCCESS_ADMIN:
			return {
				...state,
				isLawyerApproved: true,
				lawyer: {
					...state.lawyer,
					ly_verify: "approve"
				}
			}
		case APPROVE_LAWYER_ERROR_ADMIN:
			return {
				...state,
				isLawyerApproved: false
			}
		case REJECT_LAWYER_SUCCESS_ADMIN:
			return {
				...state,
				isLawyerRejected: true,
				lawyer: {
					...state.lawyer,
					ly_verify: "reject"
				}
			}
		case REJECT_LAWYER_ERROR_ADMIN:
			return {
				...state,
				isLawyerRejected: false
			}
		case CLEAR_VALUE_IN_STORE_LAWYER_ADMIN:
			return {
				...initialstate
			}
		case CHANGE_PASSWORD_LAWYER_SUCCESS_ADMIN:
			return {
				...state,
				isPasswordChanged: true
			}
		case CHANGE_PASSWORD_LAWYER_ERROR_ADMIN:
			return {
				...state,
				isPasswordChanged: false
			}
		case GET_URL_FILE_IMAGE_LAWYER_SUCCESS_ADMIN:
			return {
				...state,
				lawyer: {
					...state.lawyer,
					ly_picture_bank_url_in_cookie: action.payload.data
				}
			}
		case GET_URL_TRANSCRIPT_LAWYER_SUCCESS_ADMIN:
			return {
				...state,
				lawyer: {
					...state.lawyer,
					ly_picture_transcript_url_in_cookie: action.payload.data
				}
			}
		case GET_URL_LICENSE_LAWYER_SUCCESS_ADMIN:
			return {
				...state,
				lawyer: {
					...state.lawyer,
					ly_picture_license_url_in_cookie: action.payload.data
				}
			}
		case UPDATE_LAWYER_SUCCESS_ADMIN:
			return {
				...state,
				isLawyerUpdated: true
			}
		case UPDATE_LAWYER_ERROR_ADMIN:
			return {
				...state,
				isLawyerUpdated: false
			}
		case ACTIVE_SERVICE_SUCCESS_ADMIN:
			return {
				...state,
				isActiveSuccess: true
			}
		case ACTIVE_SERVICE_ERROR_ADMIN:
			return {
				...state,
				isActiveSuccess: false
			}
		case INACTIVE_SERVICE_SUCCESS_ADMIN:
			return {
				...state,
				isInactiveSuccess: true
			}
		case INACTIVE_SERVICE_ERROR_ADMIN:
			return {
				...state,
				isInactiveSuccess: false
			}
		case CHANGE_TASK_STATUS_LAWYER_SUCCESS_ADMIN:
			return {
				...state,
				isTaskStatusChanged: true
			}
		case CHANGE_TASK_STATUS_LAWYER_ERROR_ADMIN:
			return {
				...state,
				isTaskStatusChanged: false
			}
		case GET_URL_UPLOAD_LICENSE_SUCCESS_ADMIN:
			return {
				...state,
				urlForUploadLicense: action.payload.data,
				keyForUploadLicense: action.payload.key
			}
		case GET_URL_UPLOAD_LICENSE_ERROR_ADMIN:
			return {
				...state,
				urlForUploadLicense: null,
				keyForUploadLicense: null
			}
		case SET_VALUE_IN_STORE_LAWYER_ADMIN:
			return {
				...state,
				[action.payload.target.name]: action.payload.target.value
			}
		case GET_URL_UPLOAD_TRANSCRIPT_SUCCESS_ADMIN:
			return {
				...state,
				urlForUploadTranscript: action.payload.data,
				keyForUploadTranscript: action.payload.key
			}
		case GET_URL_UPLOAD_TRANSCRIPT_ERROR_ADMIN:
			return {
				...state,
				urlForUploadTranscript: null,
				keyForUploadTranscript: null
			}
		case UPLOAD_LICENSE_SUCCESS_ADMIN:
			return {
				...state,
				isLicenseUploaded: true
			}
		case UPLOAD_LICENSE_ERROR_ADMIN:
			return {
				...state,
				isLicenseUploaded: false
			}
		case CHANGE_LAWYER_LEVEL_SUCCESS_ADMIN:
			return {
				...state,
				isLevelChanged: true
			}
		case CHANGE_LAWYER_LEVEL_ERROR_ADMIN:
			return {
				...state,
				isLevelChanged: false
			}
		case FETCH_ORDER_HISTORY_LAWYER_SUCCESS_ADMIN:
			return {
				...state,
				orders: [...state.orders, ...action.payload.data],
				isLoading: false
			}
		case FETCH_ORDER_HISTORY_LAWYER_ERROR_ADMIN:
			return {
				...state,
				isLoading: false
			}
		case CHANGE_LAWYER_STATUS_SUCCESS_ADMIN:
			return {
				...state,
				isLawyerStatusChanged: true
			}
		case CHANGE_LAWYER_STATUS_ERROR_ADMIN:
			return {
				...state,
				isLawyerStatusChanged: false
			}
		case GET_URL_UPLOAD_BANK_SUCCESS_ADMIN:
			return {
				...state,
				urlForUploadBank: action.payload.data,
				keyForUploadBank: action.payload.key
			}
		case GET_URL_UPLOAD_BANK_ERROR_ADMIN:
			return {
				...state,
				urlForUploadBank: null,
				keyForUploadBank: null
			}
		case UPLOAD_BANK_SUCCESS_ADMIN:
			return {
				...state,
				isBankUploaded: true
			}
		case UPLOAD_BANK_ERROR_ADMIN:
			return {
				...state,
				isBankUploaded: false
			}
		default:
			return state
	}
}

export const fetchLawyers = () => {
	return api(
		"/api/lawyer",
		{
			success: FETCH_LAWYERS_SUCCESS_ADMIN,
			error: FETCH_LAWYERS_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const fetchLawyer = id => {
	return api(
		`/api/lawyer/${id}`,
		{
			success: FETCH_LAWYER_SUCCESS_ADMIN,
			error: FETCH_LAWYER_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const handleInputLawyerAdmin = ({ target }) => {
	return {
		type: HANDLE_INPUT_LAWYER_ADMIN,
		payload: target
	}
}

export const clearLawyersInStore = () => {
	return {
		type: CLEAR_LAWYERS_IN_STORE_ADMIN
	}
}

export const fetchProvinceForLawyers = () => {
	return api(
		`/public/provinces`,
		{
			success: FETCH_PROVINCE_FOR_LAWYERS_SUCCESS_ADMIN,
			error: FETCH_PROVINCE_FOR_LAWYERS_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const mapProvincesToLawyers = () => {
	return {
		type: MAP_PROVINCES_TO_LAWYERS_ADMIN
	}
}

export const approveLawyer = (id, data) => {
	return api(
		`/api/lawyer/confirm/${id}`,
		{
			success: APPROVE_LAWYER_SUCCESS_ADMIN,
			error: APPROVE_LAWYER_ERROR_ADMIN
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const rejectLawyer = (id, data) => {
	return api(
		`/api/lawyer/confirm/${id}`,
		{
			success: REJECT_LAWYER_SUCCESS_ADMIN,
			error: REJECT_LAWYER_ERROR_ADMIN
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const clearValueInStoreLawyerAdmin = () => {
	return {
		type: CLEAR_VALUE_IN_STORE_LAWYER_ADMIN
	}
}

export const changePasswordLawyer = (id, data) => {
	return api(
		`/api/lawyer/password/${id}`,
		{
			success: CHANGE_PASSWORD_LAWYER_SUCCESS_ADMIN,
			error: CHANGE_PASSWORD_LAWYER_ERROR_ADMIN
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const getUrlImageLawyer = data => {
	return api(
		`/api/upload/${data}`,
		{
			success: GET_URL_FILE_IMAGE_LAWYER_SUCCESS_ADMIN,
			error: GET_URL_FILE_IMAGE_LAWYER_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const getUrlTransciptLawyer = data => {
	return api(
		`/api/upload/${data}`,
		{
			success: GET_URL_TRANSCRIPT_LAWYER_SUCCESS_ADMIN,
			error: GET_URL_TRANSCRIPT_LAWYER_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const getUrlLicenseLawyer = data => {
	return api(
		`/api/upload/${data}`,
		{
			success: GET_URL_LICENSE_LAWYER_SUCCESS_ADMIN,
			error: GET_URL_LICENSE_LAWYER_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const updateLawyer = (id, data) => {
	return api(
		`/api/lawyer/${id}`,
		{
			success: UPDATE_LAWYER_SUCCESS_ADMIN,
			error: UPDATE_LAWYER_ERROR_ADMIN
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const activeService = id => {
	return api(
		`/api/lawyer/activeservice/${id}`,
		{
			success: ACTIVE_SERVICE_SUCCESS_ADMIN,
			error: ACTIVE_SERVICE_ERROR_ADMIN
		},
		"PUT",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const inactiveService = id => {
	return api(
		`/api/lawyer/inactiveservice/${id}`,
		{
			success: INACTIVE_SERVICE_SUCCESS_ADMIN,
			error: INACTIVE_SERVICE_ERROR_ADMIN
		},
		"PUT",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const changeTaskStatus = (id, data) => {
	return api(
		`/api/lawyer/task/${id}`,
		{
			success: CHANGE_TASK_STATUS_LAWYER_SUCCESS_ADMIN,
			error: CHANGE_TASK_STATUS_LAWYER_ERROR_ADMIN
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const getUrlUploadLicense = (id, data) => {
	return api(
		`/api/upload/lawyer/${id}`,
		{
			success: GET_URL_UPLOAD_LICENSE_SUCCESS_ADMIN,
			error: GET_URL_UPLOAD_LICENSE_ERROR_ADMIN
		},
		"POST",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const getUrlUploadTranscript = (id, data) => {
	return api(
		`/api/upload/lawyer/${id}`,
		{
			success: GET_URL_UPLOAD_TRANSCRIPT_SUCCESS_ADMIN,
			error: GET_URL_UPLOAD_TRANSCRIPT_ERROR_ADMIN
		},
		"POST",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const getUrlUploadBank = (id, data) => {
	return api(
		`/api/upload/lawyer/${id}`,
		{
			success: GET_URL_UPLOAD_BANK_SUCCESS_ADMIN,
			error: GET_URL_UPLOAD_BANK_ERROR_ADMIN
		},
		"POST",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const setValueInStoreLawyerAdmin = data => {
	return {
		type: SET_VALUE_IN_STORE_LAWYER_ADMIN,
		payload: data
	}
}

export const uploadLicense = (id, data) => {
	return api(
		`/api/lawyer/license/${id}`,
		{
			success: UPLOAD_LICENSE_SUCCESS_ADMIN,
			error: UPLOAD_LICENSE_ERROR_ADMIN
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const changeLevel = (id, data) => {
	return api(
		`/api/lawyer/level/${id}`,
		{
			success: CHANGE_LAWYER_LEVEL_SUCCESS_ADMIN,
			error: CHANGE_LAWYER_LEVEL_ERROR_ADMIN
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const fetchOrderHistory = (id, start) => {
	return api(
		`/api/task/lawyer/history/${id}?start=${start}`,
		{
			success: FETCH_ORDER_HISTORY_LAWYER_SUCCESS_ADMIN,
			error: FETCH_ORDER_HISTORY_LAWYER_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const changeStatusLawyer = (id, data) => {
	return api(
		`/api/lawyer/status/${id}`,
		{
			success: CHANGE_LAWYER_STATUS_SUCCESS_ADMIN,
			error: CHANGE_LAWYER_STATUS_ERROR_ADMIN
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}

export const uploadBank = (id, data) => {
	return api(
		`/api/lawyer/bank/${id}`,
		{
			success: UPLOAD_BANK_SUCCESS_ADMIN,
			error: UPLOAD_BANK_ERROR_ADMIN
		},
		"PUT",
		data,
		"FETCH_DATA",
		"admin"
	)
}
