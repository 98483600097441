import api from "../../service/api"

export const FETCH_DASHBOARD_COUNT_SUCCESS_ADMIN =
	"FETCH_DASHBOARD_COUNT_SUCCESS_ADMIN"
export const FETCH_DASHBOARD_COUNT_ERROR_ADMIN =
	"FETCH_DASHBOARD_COUNT_ERROR_ADMIN"

export const FETCH_ORDERS_DASHBOARD_SUCCESS_ADMIN =
	"FETCH_ORDERS_DASHBOARD_SUCCESS_ADMIN"
export const FETCH_ORDERS_DASHBOARD_ERROR_ADMIN =
	"FETCH_ORDERS_DASHBOARD_ERROR_ADMIN"

export const FETCH_LAWYERS_DASHBOARD_SUCCESS_ADMIN =
	"FETCH_LAWYERS_DASHBOARD_SUCCESS_ADMIN"
export const FETCH_LAWYERS_DASHBOARD_ERROR_ADMIN =
	"FETCH_LAWYERS_DASHBOARD_ERROR_ADMIN"

export const CLEAR_VALUE_DASHBOARD_ADMIN = "CLEAR_VALUE_DASHBOARD_ADMIN"

export const initialstate = {
	order: "",
	lawyer: "",
	customer: "",
	orders: [],
	lawyers: []
}

export default (state = initialstate, action) => {
	switch (action.type) {
		case FETCH_DASHBOARD_COUNT_SUCCESS_ADMIN:
			return {
				...state,
				...action.payload.data
			}
		case FETCH_DASHBOARD_COUNT_ERROR_ADMIN:
			return {
				...state
			}
		case FETCH_ORDERS_DASHBOARD_SUCCESS_ADMIN:
			return {
				...state,
				orders: [...action.payload.data]
			}
		case FETCH_ORDERS_DASHBOARD_ERROR_ADMIN:
			return {
				...state
			}
		case FETCH_LAWYERS_DASHBOARD_SUCCESS_ADMIN:
			return {
				...state,
				lawyers: [...action.payload.data]
			}
		case FETCH_LAWYERS_DASHBOARD_ERROR_ADMIN:
			return {
				...state
			}
		case CLEAR_VALUE_DASHBOARD_ADMIN:
			return {
				...initialstate
			}
		default:
			return state
	}
}

export const fetchCount = () => {
	return api(
		"/api/admin/dashboard",
		{
			success: FETCH_DASHBOARD_COUNT_SUCCESS_ADMIN,
			error: FETCH_DASHBOARD_COUNT_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const fetchOrders = () => {
	return api(
		"/api/order?dashboard=1",
		{
			success: FETCH_ORDERS_DASHBOARD_SUCCESS_ADMIN,
			error: FETCH_ORDERS_DASHBOARD_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const fetchLawyers = () => {
	return api(
		"/api/lawyer?dashboard=1",
		{
			success: FETCH_LAWYERS_DASHBOARD_SUCCESS_ADMIN,
			error: FETCH_LAWYERS_DASHBOARD_ERROR_ADMIN
		},
		"GET",
		{},
		"FETCH_DATA",
		"admin"
	)
}

export const clearValue = () => {
	return {
		type: CLEAR_VALUE_DASHBOARD_ADMIN
	}
}
