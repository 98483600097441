import { combineReducers } from "redux"
import { AuthCustomer, PackageCustomer, OrderCustomer } from "./customer"
import {
	AuthAdmin,
	AdminAdmin,
	PackageAdmin,
	LawyerAdmin,
	CustomerAdmin,
	OrderAdmin,
	TaskAdmin,
	DashboardAdmin
} from "./admin"
import { AuthLawyer, ProfileLawyer, TaskLawyer } from "./lawyer"

const store = combineReducers({
	AuthCustomer,
	PackageCustomer,
	OrderCustomer,
	//lawyer//
	AuthLawyer,
	ProfileLawyer,
	TaskLawyer,
	//admin//
	AuthAdmin,
	AdminAdmin,
	PackageAdmin,
	LawyerAdmin,
	CustomerAdmin,
	OrderAdmin,
	TaskAdmin,
	DashboardAdmin
	///////
})
export default store
