import { lazy } from "react"

//============================ CUSTOMER ==============================//

export const Home = lazy(() => import("./customer/home/"))
export const Login = lazy(() => import("./customer/login/Login.js"))
export const Register = lazy(() => import("./customer/register/Register.js"))
export const About = lazy(() => import("./customer/about/About.js"))
export const Contact = lazy(() => import("./customer/contact/Contact.js"))
export const ServiceDetail = lazy(() =>
	import("./customer/service/ServiceDetail.js")
)

export const ServiceDetailCustom = lazy(() =>
	import("./customer/service/ServiceCustome.js")
)
export const ForgetPassword = lazy(() =>
	import("./customer/forgetpassword/Forgetpassword.js")
)
export const ChangePasswordCus = lazy(() =>
	import("./customer/changepassword/ChangePassword.js")
)
export const ResetPassword = lazy(() =>
	import("./customer/resetpassword/ResetPassword.js")
)
export const Profile = lazy(() => import("./customer/profile/Profile.js"))
export const Payment = lazy(() => import("./customer/payment/Payment.js"))
export const OrderHistory = lazy(() =>
	import("./customer/orderhistory/OrderHistory.js")
)
export const OrderHistoryDetail = lazy(() =>
	import("./customer/orderhistory/OrderHistoryDetail.js")
)
export const Thanks = lazy(() => import("./customer/thanks/Thanks.js"))

export const TermAndCondition = lazy(() => 
import("./customer/termandcondition/TermAndCondition.js"))

export const PrivacyPolicy = lazy(() => 
import("./customer/privacypolicy/PrivacyPolicy"))

export const Faq = lazy(() => 
import("./customer/Faq/Faq"))

export const DataProtectionPolicy = lazy(() => 
import("./customer/dataprotectionpolicy/DataProtectionPolicy"))

export const RefundPolicy = lazy(() => 
import("./customer/refundpolicy/RefundPolicy"))

export const Maintenance = lazy(() => 
import("./customer/maintenance/Maintenance"))

//============================ LAWYER =================================//

export const LoginLawyer = lazy(() => import("./lawyer/login/Login.js"))
export const ForgetPasswordLawyer = lazy(() =>
	import("./lawyer/forgetpassword/ForgetPassword.js")
)
export const ChangePassword = lazy(() =>
	import("./lawyer/changepassword/ChangePassword.js")
)
export const RegisterLawyer = lazy(() =>
	import("./lawyer/register/Register.js")
)
export const EditProfile = lazy(() =>
	import("./lawyer/editprofile/EditProfile.js")
)
export const BankAccount = lazy(() =>
	import("./lawyer/bankaccount/BankAccount.js")
)
export const License = lazy(() => import("./lawyer/license/License.js"))
export const Task = lazy(() => import("./lawyer/task/Task.js"))
export const TaskDetail = lazy(() => import("./lawyer/task/TaskDetail.js"))
export const ResetPasswordLawyer = lazy(() =>
	import("./lawyer/resetpassword/ResetPassword.js")
)
export const HistoryLawyer = lazy(() => import("./lawyer/history/History.js"))
export const HistoryDetailLawyer = lazy(() =>
	import("./lawyer/history/HistoryDetail.js")
)
//============================ ADMIN =================================//

export const LoginAdmin = lazy(() => import("./admin/Login"))
export const ForgetPasswordAdmin = lazy(() => import("./admin/ForgetPassword"))
export const ResetPasswordAdmin = lazy(() => import("./admin/ResetPassword"))
export const DashBoardAdmin = lazy(() => import("./admin/Dashboard/"))
export const UserProfileAdmin = lazy(() => import("./admin/Profile"))

export const AddAdminAdmin = lazy(() =>
	import("./admin/Users/Admin/AddAdmin.js")
)
export const ViewAdminAdmin = lazy(() =>
	import("./admin/Users/Admin/ViewAdmin.js")
)
export const EditAdminAdmin = lazy(() =>
	import("./admin/Users/Admin/EditAdmin.js")
)
export const AllAdminAdmin = lazy(() =>
	import("./admin/Users/Admin/AllAdmin.js")
)
export const AllPackageAdmin = lazy(() => import("./admin/Packages/AllPackage"))
export const EditPackageAdmin = lazy(() =>
	import("./admin/Packages/EditPackage")
)
export const ViewPackgeAdmin = lazy(() =>
	import("./admin/Packages/ViewPackage")
)
export const AllOrder = lazy(() => import("./admin/Orders/AllOrder"))
export const OrderDetail = lazy(() => import("./admin/Orders/OrderDetail"))
export const AllTaskReport = lazy(() => import("./admin/Tasks/AllTaskReport"))
export const AllTaskContract = lazy(() =>
	import("./admin/Tasks/AllTaskContract")
)
export const AllTaskTransfer = lazy(() =>
	import("./admin/Tasks/AllTaskTransfer")
)
export const AllTaskReviewReport = lazy(() =>
	import("./admin/Tasks/AllTaskReviewReport")
)
export const AllTaskReviewContract = lazy(() =>
	import("./admin/Tasks/AllTaskReviewContract")
)
export const TaskAdmin = lazy(() => import("./admin/Tasks/Task"))
export const TaskReport = lazy(() => import("./admin/Tasks/TaskReportDetail"))
export const TaskContract = lazy(() =>
	import("./admin/Tasks/TaskContractDetail")
)
export const TaskTransfer = lazy(() =>
	import("./admin/Tasks/TaskTransferDetail")
)
export const TaskReviewReport = lazy(() =>
	import("./admin/Tasks/TaskReviewReportDetail")
)
export const TaskReviewContract = lazy(() =>
	import("./admin/Tasks/TaskReviewContractDetail")
)
export const TaskLawyer = lazy(() => import("./admin/Tasks/TaskLawyer"))

export const AllLawyerAdmin = lazy(() =>
	import("./admin/Users/Lawyer/AllLawyer")
)
export const EditLawyerAdmin = lazy(() =>
	import("./admin/Users/Lawyer/EditLawyer")
)
export const LawyerHistoryAdmin = lazy(() =>
	import("./admin/Users/Lawyer/LawyerHistory")
)
export const ViewLawyerAdmin = lazy(() =>
	import("./admin/Users/Lawyer/ViewLawyer")
)

export const AllCustomerAdmin = lazy(() =>
	import("./admin/Users/Customer/AllCustomer")
)
export const EditCustomerAdmin = lazy(() =>
	import("./admin/Users/Customer/EditCustomer")
)
export const CustomerHistoryAdmin = lazy(() =>
	import("./admin/Users/Customer/CustomerHistory")
)
export const ViewCustomerAdmin = lazy(() =>
	import("./admin/Users/Customer/ViewCustomer")
)
export const AdvanceSearchTask = lazy(() =>
	import("./admin/Tasks/AdvanceSearchTask")
)
export const AdvanceSearchOrder = lazy(() =>
	import("./admin/Orders/AdvanceSearchOrder")
)
export const SearchedTask = lazy(() => import("./admin/Tasks/SearchedTask"))
export const SearchedOrder = lazy(() => import("./admin/Orders/SearchedOrder"))
export const AllOrderCustom = lazy(() => import("./admin/CheckPrice/CheckPrice"))
export const OrderCustomDetail = lazy(() => import("./admin/CheckPrice/OrderCustomDetail"))